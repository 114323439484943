body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
*,
a {
  font-family: Montserrat, sans-serif !important;
}


.header {
  background-color: #062550;
  border-bottom: .5px solid #fffefe3b;
  box-shadow: 0px -1px 1px 0px #0000001A inset;
}

.ant-layout-content {
  min-height: 650px !important;
}

.profileDropdownMain button {
  color: #fff !important;
  border: none !important;
  background-color: transparent !important;
}

section.ant-layout.layout-dashboard {
  overflow: hidden;
}

button.btn-first,
button.btn-first.ant-btn:hover,
button.btn-first.ant-btn:focus {
  background: #CE9944;
  color: black;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 96px;
  box-shadow: 0px 10.394088745117188px 41.57635498046875px 0px #c5745830;
  height: 43px;
  padding: 11px 33px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  box-shadow: 0px 10.39px 41.58px 0px #c5745830;
}

.right-header-menu {
  display: flex;
  align-items: center;
  gap: 8px;
}

.userImg {
  flex: 0 0 37px;
}

button.btn-first-1,
button.ant-btn.ant-btn-default.btn-first-1:hover,
button.ant-btn.ant-btn-default.btn-first-1:focus,
button.ant-btn.ant-btn-default.btn-first-1:active {
  border: none;
  color: #CE9944;
  background-color: transparent !important;
  font-size: 16px;
  line-height: 19px;
  font-weight: 800;
}


.header-button-main1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

section.tabbs-section li.nav-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
}

.Social-Links-logo img {
  width: 100%;
  height: 50px;
  max-width: 50px;
}

section.tabbs-section .nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border: none !important;
}

.star-imgg img {
  width: 22px;
  height: 22px;
}

.ant-form-item-control-input .ant-form-item-control-input-content textarea#description {
  border-radius: 7px !important;
}

.offer-img img {
  max-width: 100px;
  height: 100px;
  background: linear-gradient(white, white) padding-box, #CE9944 border-box !important;
  border-radius: 14px !important;
  border: 2px solid transparent;
  padding: 5px;
  width: 100%;
}

.ant-tabs.ant-tabs-top.offering-tabbs .ant-tabs-tab {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 35px;
  font-weight: 400;
  color: #121212;
}

.ant-tabs.ant-tabs-top.offering-tabbs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #f3b000;
  font-weight: 500;
  border-color: #062550;
  font-size: 20px;
}

.gallery-items img {
  object-fit: cover;
}

.ant-tabs.ant-tabs-top.offering-tabbs button#rc-tabs-0-more {
  display: none;
}

.ant-tabs.ant-tabs-top.offering-tabbs .ant-tabs-nav-list {
  background: #EEF5FF;
  height: 60px;
  width: 100%;
}

.ant-tabs.ant-tabs-top.category-tab .ant-tabs-tab {
  width: 200px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
  color: #073C51;
  border-radius: 12px;
}


section.tabbs-section {
  padding-top: 50px;
  padding-bottom: 50px;
}

.nav-link {
  color: black;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  line-height: 19px;
}

.logo-android {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 14px;
}

.navbar-nav {
  gap: 30px;
}

.main-hero-bg {
  background: #062550;
  /* background-position: bottom;
     */
  padding-bottom: 134px;
  padding-top: 57px;
  overflow: hidden;
  margin-top: 0px;
  /* position: relative;
     */
  clip-path: ellipse(80% 77% at 55% 20%);
}

section.download-bg {
  background: #0625501a;
  padding-top: 50px !important;
  margin-bottom: 50px;
}

.left-bar-slaide h6 {
  font-size: 53px;
  font-weight: 700;
  line-height: 64px;
  /* letter-spacing: 0em;
     */
  color: #fff;
}

.left-bar-slaide h2 {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  color: #fff;
  width: 100%;
  max-width: 630px;
}

.left-bar-slaide {
  margin-top: 0;
  position: relative;
  min-height: 100%;
  width: 100%;
  max-width: 668px;
}

.slaider-btn {
  padding-top: 25px;
}

section.booking-section {
  background-color: #fff;
  padding: 100px 0px;
  margin: 0px !important;
}

.headding h4 {
  font-size: 42px !important;
  font-weight: 700 !important;
  line-height: 51px !important;
  letter-spacing: 0em;
  color: #062550;
  margin: 0px;
  padding-bottom: 10px;
}

button#button-main-3 {
  background: #062550;
  color: white;
}

.testimonial-sec {
  padding-bottom: 50px;
  background: #e6e9ed;
  padding-top: 50px;
}

.teext2 {
  text-align: center;
  margin-bottom: 20px;
}

.teext2 h4 {
  font-size: 42px;
  font-weight: 800;
  color: #062550;
}

.main-img {
  position: relative;
  width: 60%;
  /* border: 1px solid #FB035C; */
  height: 100%;
  padding-left: 18px !important;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #062550;
  background-color: white;
  padding: 22px 0px;
  min-height: 300px;
}

button.owl-dot.active {
  background: #062550 !important;
}

button.owl-dot {
  background: #06255080 !important;
  width: 10px;
  height: 10px;
  margin: 10px;
  border-radius: 50%;
}

.testimonial-sec .owl-dots {
  padding: 35px 0 0;
  text-align: center;
}

.container.for-width .accordion-body {
  background: #e6e9ed !important;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: #4E4E4E;
  padding: 0px;
  padding-bottom: 33px;
  border-bottom: 1px solid #C7C7C7 !important;
}

form#file-upload-form .hidden {
  display: none;
}

.ant-modal.main-model_imgg1 .ant-modal-header {
  display: none;
}

.ant-modal.main-model_imgg1 .ant-modal-body {
  padding: 0px;
}

.ant-modal.main-model_imgg1 .ant-modal-footer {
  display: none;
}

.ant-modal.main-model_imgg1 .ant-modal-content {
  border-radius: 50px;
  overflow: hidden;
  padding: 0px;
}

h2.freq-ask-qqq.accordion-header button.accordion-button {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0px;
  background-color: #e6e9ed !important;
  color: #1a1a1a;
  box-shadow: none;
  padding: 15px 10px !important;
}

/* h2.freq-ask-qqq.accordion-header button.accordion-button.collapsed {
  padding: 33px 0px !important;
  border-bottom: 1px solid #C7C7C7 !important;
} */
.accordion.faq-accordion .accordion-button::after {
  position: absolute;
  right: 20px;
  bottom: 33px;
}

section.faq-section {
  background: #e6e9ed;
  padding: 50px 0px;
}

.ant-tabs.ant-tabs-top.budget-main-hddr .accordion-button::after {
  position: absolute;
  top: 22px;
  left: 27px;
  background-size: 24px;
  width: 23px;
  height: 23px;
}

.ant-tabs.ant-tabs-top.budget-main-hddr button.accordion-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  padding: 10px 20px;
}

/* .ant-tabs.ant-tabs-top.budget-main-hddr .main-quotation-detailss .accordion {
  height: 100% !important;
} */
.main-quotation-detailss .accordion-button::after {
  display: none;
}

.main-quotation-detailss button.accordion-button {
  background: #f9f9fc;
}

.accordion.faq-accordion .accordion-item {
  border: none;
  padding-bottom: 32px;
  background: #e6e9ed;
  border-bottom: 1px solid #c7c7c7;
}

.ant-tabs.ant-tabs-top.budget-main-hddr .accordion-item {
  border-radius: 20px !important;
  overflow: hidden;
  border: 1px solid #EEF5FF !important;
}

.ant-tabs.ant-tabs-top.budget-main-hddr button.accordion-button h1 {
  margin: 0;
}

.ant-tabs.ant-tabs-top.budget-main-hddr button.accordion-button h2 {
  font-size: 22px;
  font-weight: 600;
  line-height: 18px;
  color: #021C3F;
  margin: 0;
  padding: 0;
  margin-left: 45px;
}

/* color: white !important;
 text-decoration: none;
 outline: none;
 background: #062550;
 */
section.choose-us {
  margin-bottom: 50px;
  margin-top: 50px;
}

.ant-tabs.ant-tabs-top.message-min .ant-tabs-tab {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
  color: #7A797A;
  font-size: 22px;
  line-height: 18px;
  font-weight: 400;
}

.ant-tabs.ant-tabs-top.message-min {
  background: white;
  margin-top: 40px;
  border-radius: 20px !important;
  overflow: hidden;
}

.ant-tabs.ant-tabs-top.message-min {
  background: white;
  margin-top: 40px;
  border-radius: 20px !important;
  overflow: hidden;
  min-height: 500px;
  height: 100%;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.ant-tabs.ant-tabs-top.message-min .ant-tabs-nav {
  margin-bottom: 0px !important;
  padding: 0px !important;
}

.ant-tabs-tab.ant-tabs-tab-active {
  background: #062550;
  border-radius: 10px;
}

/* button.accordion-button.faq-btn {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0px;
  padding-top: 25px;
  background-color: #fff;
  color: #1a1a1a;
  border-bottom: 0px solid !important;
  box-shadow: none;
  background: #e6e9ed !important;
} */
.accordion-item:first-of-type .accordion-button.faq-btn {
  padding-top: 0px;
  background: #e6e9ed !important;
}

section.bg-img2 {
  padding: 50px 0px;
}

ul.list3 img,

.logo-img4 img,
.wedding-main {
  cursor: pointer;
}

.gmail-box {
  max-width: 610px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  height: 60px;
  border-radius: 60px;
  padding-right: 8px;
  padding-left: 26px;
  background: #e8f0fe;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.gmail-box .ant-form-item {
  margin: 0;
}

.gmail-box button.ant-btn.ant-btn-default {
  color: white;
  font-size: 22px;
}

.header_bg ul.dropdown-menu.show li {
  background: #062550;
}

.gmail-box img {
  width: 22px;
  position: absolute;
  left: 20;
}

.gmail-box input {
  border: none;
  width: 100%;
  height: 100%;
  margin: 0 21px;
  outline: 0 !important;
  box-shadow: none !important;
  background: #e8f0fe;
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  border: none !important;
}

.gmail-box button {
  height: 50px;
  border: none;
  border-radius: 60px;
  padding: 0 30px;
  background: #062550 !important;
  max-width: -moz-fit-content;
  max-width: fit-content;
  width: 100%;
  font-size: 16px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #062550;
}

.mail-box h4 {
  font-size: 36px;
  font-weight: 700;
  color: #062550 !important;
  line-height: 52px;
  margin: 0px;
}


.for-fllx p {
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  width: 80%;
  padding-top: 10px;
}

.for-fllx h3 {
  font-size: 22px;
  font-weight: 500;
  line-height: 27px;
  color: white;
  margin: 0px;
  padding-bottom: 10px;
}

ul.list2 li h1 {
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  color: white;
  margin: 0px;
  padding-bottom: 20px;
}

ul.list2 li p {
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  width: 80%;
  padding-bottom: 21px;
  margin: 0px;
}

.from-main {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0px;
}

.tophead h3 {
  font-size: 42px;
  line-height: 57px;
  font-weight: 700;
  color: #062550;
}

.tophead p {
  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
  padding-bottom: 48px;
}

.form-label {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.1px;
  color: #121212;
}

.form-control {
  border: 1px solid #d0d5dd !important;
  background: #fbfbfb !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 16.1px !important;
  padding: 12px 16px 12px 16px !important;
  border-radius: 8px !important;
  color: #a2a2b5 !important;
}

.form-control:focus {
  box-shadow: none !important;
}

section.bg-img2 .form-11 {
  border-radius: 0 16px 16px 0;
  padding: 30px 38px 30px 38px;
  background-color: #FBFBFB;
  border: 1px solid var(--Primary, #DDDDDD);
}

button.btn.btn-primary {
  width: 100%;
  height: 54px;
  background: #062550;
  box-shadow: 0px 5px 10px 0px #c5745830;
  padding: 0px 55px 0px 55px;
  border-radius: 60px;
  font-size: 18px;
  font-weight: 700;
  line-height: 25.2px;
}

/* .bar {
     height: 6px;
     width: 150px;
     background: #216fa7;
     margin: 15px 0px;
     position: relative;
     border-radius: 5px;
     overflow: hidden;
}
 .bar::before {
     content: '';
     position: absolute;
     left: 0;
     top: 0;
     height: 100%;
     width: 26px;
     background: #fff;
     -webkit-animation-duration: 2s;
     animation-duration: 2s ease-in-out;
     -webkit-animation-timing-function: linear;
     animation-timing-function: linear;
     -webkit-animation-iteration-count: infinite;
     animation-iteration-count: infinite;
     -webkit-animation-name: MOVE-BG;
     animation-name: MOVE-BG;
     height: 6px;
     border-radius: 20px;
}
 */
@-webkit-keyframes MOVE-BG {
  from {
    -webkit-transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(150px);
  }
}

@keyframes MOVE-BG {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(150px);
    transform: translateX(150px);
  }
}

.groth::after {
  position: absolute;
  content: "";
  top: 40px;
  bottom: 0;
  right: 0;
  left: 100%;
  width: 0.98px;
  height: 115px;
  border: 1px solid #d0d5dd;
}

.groth {
  text-align: center;
  width: 33%;
  position: relative;
}

.main-hero-bg::after {
  position: absolute;
  content: "";
  background-image: url(../images/Ellipse\ 71.png);
  width: 70%;
  height: 100%;
  top: 0;
  left: 0;
  gap: 0px;
  opacity: 70%;
  background-repeat: no-repeat;
  z-index: -1;
}

.main-hero-bg {
  position: relative;
}

.booking-list li {
  display: flex;
  gap: 16px;
  margin-bottom: 27px;
}

.booking-list h2 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
  line-height: 30px;
  color: #062550;
}

.booking-list li div p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  width: 306px;
  color: #062550;
}

.booking-list ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.booking-list li span {
  background-color: transparent;
  /* width: 50px;
     */
  /* height: 32px;
     */
  flex: 0 0 32px;
}

.booking-list li h6 {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  color: #1a1a1a;
  margin: 0px;
}

.book-right button.btn-first {
  max-width: 325px;
  width: 100%;
  height: 54px;
}

.teext2 p {
  font-size: 18px;
  margin-bottom: 0;
  line-height: 30px;
  font-weight: 400;
  color: #062550;
}

.businesswomenimg img {
  width: 100%;
  height: 100%;
  border-radius: 16px 0px 0px 16px !important;
}

.containerni .mb-3 {
  margin-bottom: 29px !important;
}

.sendbiuttondiv {
  padding-top: 24px;
}

.accordion.faq-accordion .accordion-body {
  padding: 7px 0px;
  font-size: 15px;
  color: #989898;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  margin-top: 0px;
}

.container.for-width .accordion-button::after {
  background-image: url(../images/add-icon.svg) !important;
}

.for-border {
  border-bottom: 1px solid #3e3e3e;
  padding-bottom: 20px;
  margin-bottom: 0px;
}

.gmail-box-1 {
  width: 346px;
  height: 48px;
  background-color: #e8f0fe !important;
  justify-content: space-between;
  align-items: center;
  border-radius: 7px;
  display: flex;
  padding-left: 16px;
  background: #e6e9ed;
}

.gmail-box-1 input {
  border: none;
  width: 100%;
  height: 100%;
  margin: 0 21px;
  outline: 0 !important;
  box-shadow: none !important;
  background: #e8f0fe;
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  border: none !important;
}


.gmail-box-1 button {
  width: 126px;
  height: 46px;
  border: none;
  border-radius: 0 7px 7px 0;
  padding: 8px 16px;
  background: #062550;
  margin-right: 1px;
}

ul.list2 li a {
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  color: white;
  line-height: 19px;
}

.groth:last-child::after {
  display: none;
}

h2.freq-ask-qqq.accordion-header .accordion-item {
  border: none !important;
  border-bottom: 1px solid #C7C7C7;
}

.trigger {
  border-radius: 10px;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.bars {
  width: 50px;
  cursor: pointer;
}

.bars .line {
  fill: none;
  stroke: #fff;
  stroke-width: 4;
  stroke-linecap: square;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
}

.bars .line.top {
  stroke-dasharray: 40 172;
}

.bars .line.middle {
  stroke-dasharray: 40 111;
}

.bars .line.bottom {
  stroke-dasharray: 40 172;
}

.bars.active .top {
  stroke-dashoffset: -132px;
}

.bars.active .middle {
  stroke-dashoffset: -71px;
}

.bars.active .bottom {
  stroke-dashoffset: -132px;
}

button.navbar-toggler {
  box-shadow: none !important;
  border: none !important;
}

.modal-content {
  border-radius: 45px !important;
  overflow: hidden;
}

.name-1.modell {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 22px;
  padding-bottom: 20px;
}

.model-logo {
  padding-bottom: 30px;
}

button.btn.btn-secondary {
  background: #021c3f;
  width: 100%;
  height: 60px;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 40px;
  color: #f2c230;
}

.model-logo {
  padding-bottom: 25px;
  display: flex;
  align-items: center;
}

.name-1 .form-control.for-padd {
  margin-bottom: 20px !important;
}

.model-logo a {
  color: #062550;
  font-size: 22px;
  font-weight: 600;
  line-height: 27px;
  padding-left: 12px;
}

.model-logo.frgt-p {
  padding-bottom: 30px !important;
}

.model-form-1 {
  padding: 223px 67px;
}

.model-form h2 {
  line-height: 22px;
  font-weight: 600;
  font-size: 18px;
  color: var(--Gray, #757575);
  margin-top: 40px;
  text-align: center;
}

.model-form.new h2 {
  line-height: 22px;
  font-weight: 600;
  font-size: 18px;
  color: var(--Gray, #757575);
  margin-top: 50px !important;
  text-align: center;
}

.model-form h2 a {
  color: rgba(6, 37, 80, 1);
}

.modal-body {
  padding: 0px !important;
}

.model-form-main {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.model-form {
  padding: 150px 67px;
  position: relative;
  /* margin: 0px auto;
     */
  background-color: #e6e9ed;
}

.name-1 .form-control {
  background: white !important;
  border: 1px solid rgba(217, 217, 217, 1);
  height: 54px;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 19.5px !important;
  color: var(--Placeholder, rgba(167, 167, 167, 1)) !important;
}

.model-img img {
  width: 100%;
  height: 100%;
  border-radius: 0px 0px 0px 10px;
}



.name-2 .form-control {
  background: white !important;
  border: 1px solid rgba(217, 217, 217, 1);
  height: 55px;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 19.5px !important;
  color: var(--Placeholder, rgba(167, 167, 167, 1)) !important;
  margin-bottom: 20px;
}

.model-form h3 {
  margin: 0px;
  text-align: end;
  margin-top: 10px !important;
}

.model-form h3 a {
  line-height: 22px;
  font-size: 19px;
  font-weight: 600;
  color: rgba(6, 37, 80, 1);
}

.model-form-main-1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  background: #E6E9ED;
}

.otp {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
  padding-bottom: 22px;
  width: 100%;
  max-width: 435px;
  gap: 21px;
}

.otp-number {
  width: 55px;
  height: 55px;
  border: 1px solid #d9d9d9;
  border-radius: 7px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-close {
  background-image: url(../images/close-buttom.png) !important;
  background-size: cover !important;
  opacity: unset !important;
}

.modal-header {
  border: none !important;
  position: absolute;
  top: 12px;
  right: 12px;
}

.btn-close:focus {
  box-shadow: none !important;
}

.btn-close:hover {
  opacity: unset !important;
}

.trogle-hh {
  position: relative;
}

.trogle-hh i {
  position: absolute;
  top: 12px;
  right: 16px;
}

/* .navbar-main-menu {
     flex-basis: 100%;
     flex-grow: 1;
     align-items: center;
}
 .navbar-main-menu {
     flex-basis: 100%;
     flex-grow: 1;
     align-items: center;
     position: absolute;
     right: -600px;
     width: 200px;
}
 */
.model-img {
  position: relative;
  width: 100%;
  height: 100%;
}

.main-img-9 {
  width: 100%;
  height: 100%;
}

.before-img::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 160px;
  height: 88px;
}

.main-header {
  font-size: 16px;
  color: white;
  font-weight: 500;
  display: flex;
  justify-content: end;
  align-items: center;
  height: 47px;
}

.phone-book img {
  width: 25px;
  height: 21px;
  filter: invert(1);
  margin-right: 2px;
  margin-bottom: 2px;
}

.world-book img {
  width: 20px;
  height: 20px;
  margin-right: 2px;
}

.world-book {
  margin-left: 22px;
}

.background-main {
  background: linear-gradient(110deg, #ff8e4f 5%, #fb035c 100%, #c51a98 100%);
}

.scanner {
  background-color: white;
  border-radius: 10px;
  padding: 0px;
  width: 138px;
  height: 136px;
}

div#navbarNavAltMarkup {
  justify-content: right;
  align-items: center;
  margin: 22px 0px;
}

a.navbar-brand img {
  width: 100%;
  height: 100%;
}

a#book {
  background-color: #ff8e4f;
  border-radius: 60px;
  width: 207px;
  height: 43px;
  text-align: center;
}

nav.navbar.navbar-expand-lg.navbar-light.bg-light {
  background-color: white !important;
  padding: 0px;
  margin: 0px;
}

a.nav-link.active {
  font-weight: 600 !important;
  color: #ddaf62 !important;
}

.image4 img {
  width: 195px;
  height: 61px;
}

.code {
  display: flex;
  align-items: center;
}

.image6 h5 {
  margin-top: 42%;
}

section.background3 {
  background-image: url(../images/Rectangle\ 4300.png);
  padding-top: 78px;
  border-radius: 0px 0px 35% 35%;
  overflow: hidden;
}

.main4 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  padding-top: 10px;
}

.teext5 {
  padding: 50px 0px;
}

.teext {
  text-align: center;
  padding: 0;
  margin-bottom: 35px;
}

.teext h4 {
  font-size: 42px;
  font-weight: 800;
}

.text3 {
  background-color: #f1f1f1;
  border-radius: 15px;
  padding: 36px 38px;
  font-size: 15px;
  line-height: 34px;
  text-align: justify;
}

.teext span {
  background: linear-gradient(304deg,
      #ff8e4f 1.75%,
      #fb035c 4.36%,
      #c51a98 141.36%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.groth-main {
  display: flex;
  align-items: center;
  padding: 50px 0px;
  justify-content: space-between;
}

.groth h2 {
  font-size: 35px;
  padding-top: 15px;
  font-weight: 700;
  color: #1a1a1a;
  padding-top: 15px !important;
}

.groth h4 {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 0;
  color: #1a1a1a;
}

.female-img {
  padding-left: 30%;
}

.female-img img {
  width: 435px;
  height: 665px;
}

.patian img {
  width: 320px;
  height: 94px;
}

.patian {
  position: absolute;
  top: 50%;
  left: 38%;
}

.cup {
  background-color: white;
  border-radius: 50%;
  width: 98px;
  height: 98px;
  align-items: center;
  justify-content: center;
  display: flex;
  position: absolute;
  top: 36%;
  right: 14%;
}

.doct {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  width: 280px;
  height: 116px;
  border-radius: 19px;
  position: absolute;
  top: 120%;
  left: 70%;
  padding-left: 30px;
}

.doct h3 {
  font-size: 27px;
  padding: 0px;
  margin: 0px;
}

.doct p {
  font-size: 16px;
  padding: 0px;
  margin: 0px;
}

.const {
  margin-left: 20px;
}

.arth-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
}

.arth {
  background-color: #ffe9f1;
  border-radius: 11px;
  /* align-items: center;
     */
  /* justify-content: center;
     */
  /* display: flex;
     */
  text-align: center;
  font-size: 19px;
}

.arth2 p {
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  max-width: 80%;
  margin: 20px auto 0;
}

.left-bar-slaide span {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  color: #fff;
}

a {
  text-decoration: none !important;
}

.right-bar-slide {
  margin-top: 0;
  position: relative;
  min-height: 100%;
  text-align: center;
}

.slide-img01 {
  width: 100%;
  max-width: 400px;
  position: relative;
  z-index: 9;
  bottom: -80px;
}

.right-top-menu img {
  position: absolute;
  top: 50px;
  right: 11%;
  width: 98px;
  height: 98px;
  z-index: 99;
}

.left-top-menu img {
  max-width: 200px;
}

.left-top-menu img {
  position: absolute;
  right: 58%;
  top: 100px;
  max-width: 319px;
  height: 93px;
  width: 100%;
  z-index: 99;
}

.right-bottam-menu img {
  position: absolute;
  bottom: -40px;
  right: 25px;
  z-index: 50;
}

.slaider-btn a img {
  width: 195px;
  height: 62px;
}

ul.navbar-nav.ms-auto.mb-2.mb-lg-0 {
  padding-right: 10px;
}

.logo-android div {
  margin: 8px 0px;
}

.book-left img {
  width: 100%;
}

span.text-color {
  background: linear-gradient(304deg,
      #ff8e4f 1.75%,
      #fb035c 4.36%,
      #c51a98 141.36%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.headding span {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #062550;
}

.book-right button.btn-first {
  max-width: 325px;
  width: 100%;
  height: 54px;
}

ul {
  padding: 0px !important;
  margin: 0px !important;
}

.main-img h5 {
  width: 54px;
  height: 54px;
}

.main-img p {
  max-width: 80%;
  font-size: 16px;
  line-height: 28px;
  text-align: justify;
  color: #062550;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.testimonial-wrap {
  display: flex;
  align-items: center;
}


.main-img2 img {
  border-radius: 10px;
  max-width: 250px !important;
  width: 100%;
  height: 200px !important;
  position: relative;
  z-index: 1;
}

.main-img2 {
  width: auto !important;
  max-width: calc(100% + 20%);
  margin-left: -20%;
  position: relative;
  z-index: 1;
}

.author-wrap {
  max-width: 80%;
}

.aayur h4 {
  font-size: 20px;
  margin-top: 20px;
  font-weight: 600;
}

.aayur p {
  font-size: 16px;
  margin: 0px;
  padding: 0px;
  line-height:
    31px;
  font-weight: 400;
}

.aayur {
  border: 1px solid #a2a2b5;
  border-radius: 14px;
  width: 100%;
  padding: 15px 15px 20px;
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: left;
  margin: 4px 0px;
}

.imggg4 img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.imggg4 {
  height: 210px;
  overflow: hidden;
  border-radius: 10px;
}

.calander {
  display: flex;
  align-items: center;
  padding-top: 12px;
}

.calander p {
  padding-left: 13px;
  font-size: 12px;
  font-weight: 500;
  line-height: 31px;
}

.arth2 {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 12px 28px;
  border: 1px solid #f0f0f0;
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 235px;
}

.img-radious {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 128px;
  height: 128px;
  background: #ffe9f1;
  border-radius: 50%;
  margin: 0 auto;
}

.button-main1 {
  text-align: center;
  padding: 20px 0px;
}

.button-main1 button {
  padding: 12px 50px;
  border: none;
  border-radius: 60px !important;
  color: #021c3f !important;
  background: white !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  width: 170px !important;
  height: 50px !important;
}

.button-main1 .button1 img {
  rotate: 180deg;
}




.accordion.faq-accordion {
  margin-top: 35px;
}

.accordion-header {
  margin-bottom: 0;
}

button.accordion-button.faq-btn:not(.collapsed)::after {
  background-image: url(../images/minus.svg) !important;
  transform: rotate(-180deg);
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  background-color: white;
  color: black;
}

.mail-box {
  text-align: center;
}

.mail-box p {
  font-size: 16px;
  font-weight: 400;
  line-height: 37px;
  margin: 0;
  padding-bottom: 28px;
  color: #0d2444;
}



.gmail-box button a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 17px;
}

/* .form-select {
  width: 189px !important;
} */
.ant-col.ant-form-item-label label {
  color: #414454 !important;
  font-size: 14px !important;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
}

.ant-picker {
  overflow: hidden !important;
}

.logo-img5 {
  display: flex;
  align-items: center;
  padding-top: 10px;
  gap: 5px;
}

section.bg-img3 {
  padding: 50px 0px 10px;
  background-color: #021c3f;
}

ul.list2 li {
  list-style: none;
  margin-bottom: 8px;
}

section.tabbs-section .owl-next {
  position: absolute;
  top: 44%;
  right: 0;
}

section.tabbs-section .owl-prev {
  position: absolute;
  top: 44%;
  left: 0;
}

section.tabbs-section .owl-nav div {
  background: #021c3f !important;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

section#gallary .owl-nav div {
  background: #021c3f !important;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

div#attribute-container .ant-checkbox-wrapper+.ant-checkbox-wrapper {
  margin: 0px !important;
  margin-top: 8px !important;
}

.main-eve-listed {
  display: grid;
  gap: 2px;
  margin-bottom: 12px;
}

div#attribute-container .ant-radio-group {
  display: flex;
  gap: 7px;
  flex-wrap: wrap;
}

.add_user_title h4.modal_title_cls {
  justify-content: center;
}

.gallery-img3.gallery-img3-main-gallery-new987 img {
  width: 100%;
  max-width: 292px;
  max-height: 200px;
  height: 100%;
}

section#gallary .owl-next {
  position: absolute;
  top: 44%;
  right: 0;
}

section#gallary .owl-prev {
  position: absolute;
  top: 44%;
  left: 0;
}








.copy img {
  width: 15px;
  margin-right: 8px;
}

.copy {
  color: white;
  font-size: 14px;
  font-weight: 400;
  padding-top: 8px;
}

ul.list3 {
  display: flex;
  gap: 8px;
  justify-content: end;
  padding-top: 8px !important;
}

ul.list3 li a {
  color: white;
  font-size: 16px;
}

.scanner1 {
  margin-top: 100px;
  color: white;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  text-align: left;
}

.for-p {
  padding: 105px 0px;
}

section.bg-img5::af ter {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  opacity: 0.6;
  z-index: 0;
}

/* .bg-img5 {
  background-image: url(../images/front-view-mother);
  height: 678px;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: cover;
  align-items: center;
  display: flex;
  text-align: center;
  position: relative;
} */
.about-text p {
  font-size: 18px;
  line-height: 36px;
  font-weight: 700;
}

.about-text {
  color: white;
  z-index: 2;
  position: relative;
}

.about-text h2 {
  font-size: 52px;
  line-height: 63px;
  font-weight: 700;
}

.ceo-name-main {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin: 34px 0px;
}

.ceo-img img {
  width: 100%;
  max-width: 360px;
  height: 360px;
  object-fit: cover;
}

.ceo-name {
  text-align: center;
  background: linear-gradient(304deg,
      #ff8e4f 1.75%,
      #fb035c 4.36%,
      #c51a98 141.36%);
  margin-top: 25%;
  position: relative;
  color: white;
}

.ceo-img {
  border: 30px solid #fb035c;
  max-width: 421px;
  height: 421px;
  border-radius: 50%;
  width: 100%;
  overflow: hidden;
  color: white;
  margin: -137px auto 0;
}

.ceo-name h2 {
  font-size: 32.08px;
  font-weight: 700;
  line-height: 39.11px;
  font-family: "Montserrat" !important;
}

.ceo-text {
  margin: 20px 0px;
}

.ceo-name h6 {
  font-family: Montserrat !important;
  font-size: 22.06px;
  font-weight: 500;
  line-height: 26.89px;
}

.ceo-name p {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

.ceo-m {
  margin: 105px 0px;
}

.headding.text-left {
  border: 2px solid #ececec;
  padding: 33px 40px;
  box-shadow: 0px 0px 5px 0px #fb035c66;
  border-radius: 2px;
}

.headding.text-left p {
  padding: 0px;
  margin: 0px;
  color: #898989;
  font-size: 18px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
}

.our-story {
  margin: 105px 0px;
}

.our-img img {
  width: 100%;
  height: 586px;
  object-fit: cover;
  border-radius: 10px;
}

.our-img {
  width: 100%;
  overflow: hidden;
  padding-bottom: 23px;
  padding-left: 23px;
  position: relative;
}

.our-img::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  background: linear-gradient(131.72deg,
      #ff8e4f 2.75%,
      #fb035c 51.36%,
      #c51a98 94.36%);
  width: 80%;
  max-width: 500px;
  height: 500px;
  border-radius: 0px 0px 0px 10px;
  z-index: -1;
}

.office-pic img {
  width: 100%;
  height: 100%;
}

.headding-2 {
  margin-top: 12%;
  padding-left: 3%;
}

.headding-2 h4 {
  font-size: 36px;
  line-height: 50px;
  font-weight: 700;
  margin: 0px;
}

.headding-2 .text-color {
  font-size: 36px !important;
}

.headding-2 p {
  color: #898989;
  margin: 0px;
  font-size: 18px;
  font-weight: 500;
  line-height: 34px;

}

section.office-background {
  background: rgba(255, 233, 241, 1);
  padding: 105px 0px;
}

.mission {
  border: 1px solid rgba(255, 233, 241, 1);
  border-radius: 15px;
  box-shadow: 0px 0.394089px 5.576355px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-bottom: 50px;
  height: calc(100% - 50px);
  padding: 32px 14px;
}

.mission-img img {
  width: 100%;
  max-width: 320px;
}

.mission-img h3 {
  font-size: 32px;
  line-height: 41px;
  font-weight: 700;
  background: linear-gradient(304deg,
      #ff8e4f 1.75%,
      #fb035c 4.36%,
      #c51a98 141.36%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 15px;
}

.mission-img p {
  color: rgba(137, 137, 137, 1);
  margin-top: 15px;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 0px;
}

.download-page h3 {
  font-size: 42px;
  line-height: 51px;
  font-weight: 600;
  color: #062550;
  margin: 0px;
}

.download-page p {
  font-size: 16px;
  line-height: 31px;
  font-weight: 400;
  color: #062550;
  margin: 0px;
  padding-top: 25px;
}

.book-right .headding {
  padding: 20px 0px;
}

span.text-colors {
  background: linear-gradient(304deg,
      #ff8e4f 1.75%,
      #fb035c 4.36%,
      #c51a98 141.36%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.whatsapp-image {
  width: 100%;
  height: 100%;
}

.download-pages p {
  font-size: 18px;
  line-height: 30px;
  font-weight: 700;
  color: #1a1a1a;
  width: 100%;
  margin-top: 20px;
}

.download-pages h3 {
  font-size: 42px;
  line-height: 51px;
  font-weight: 700;
  color: rgba(0, 0, 0, 1);
}

.check-button {
  display: flex;
  align-items: center;
  gap: 10px;
}

.check-text {
  font-size: 16px;
  font-weight: 700;
  line-height: 40px;
}

/* section.whatsapp-f {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 476px;
  text-align: center;
  background-image: url(../images/whats.png);
  color: white;
  margin: 105px 0px;
  background-repeat: no-repeat;
  background-size: cover;
} */
.whatsapp-text h3 {
  font-size: 42px;
  line-height: 58px;
  font-weight: 700;
}

.whatsapp-text p {
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
}

.box-main {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
}

.ayushakti {
  margin: 10px 0px;
}

section.aayu-section {
  margin: 20px 0px;
}

.latest-box {
  display: flex;
  align-items: end;
  text-align: center;
  gap: 8px;
  justify-content: end;
}

.latest-text p {
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  margin: 0px;
}

.latest-text h2 {
  font-size: 42px !important;
  line-height: 51px;
  font-weight: 700;
  color: #121212;
}

.box-1 input {
  max-width: 290px;
  height: 43px;
  width: 100%;
  color: black;
  border: 1px solid #d0d5dd;
  border-radius:
    8px;
  padding: 12px 16px;
  line-height: 19;
  font-weight: 400;
  background-color: white;
  font-size: 16px;
}

.box-5 button {
  width: 122px;
  height: 43px;
  border: none;
  border-radius: 10px;
  color: white;
  background: linear-gradient(110deg, #ff8e4f 5%, #fb035c 100%, #c51a98 100%);
}

button#dropdownMenuButton1 {
  color: black;
  background-color: white;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  height: 43px;
  border-radius: 9px;
  border: 1px solid #d0d5dd;
}

.button-main1 {
  text-align: center;
  padding: 30px 0px 0;
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  margin: 10px 0px;
  width: 100%;
}

/* div#main-2 button {
  line-height: 29px;
  font-size: 20px;
  gap: 20px;
  font-weight: 600;
} */

div#main-2 button img {
  margin-right: 5px;
  width: 25px;
  height: 25px;
}

.khapa-img img {
  width: 100%;
}

.contant-1 {
  margin: 30px 0px;
}

.contant-1 h2 {
  font-size: 58px;
  line-height: 70px;
  font-weight: 700;
}

.contant-1 h5 {
  font-size: 34px;
  line-height: 40px;
  margin: 20px 0px;
  font-weight: 400;
}

.contant-1 h4 {
  font-size: 36px;
  line-height: 44px;
  font-weight: 700;
  padding-top: 20px;
}

.contant-1 p {
  font-size: 28px;
  line-height: 45px;
  font-weight: 400;
  padding: 0px 0px;
}

.contant-1 h3 {
  font-size: 46px;
  line-height: 55px;
  font-weight: 700;
  padding-top: 15px;
}

.gallery-img img {
  width: 100%;
  height: 333px;
}

.gallery-main {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}

.gallery-img {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
}

.gallery-text {
  padding: 20px 23px;
}

.gallery-text h4 {
  font-size: 20px;
  line-height: 31px;
  font-weight: 600;
  color: black;
}

.gallery-text p {
  font-size: 16px;
  line-height: 31px;
  font-weight: 400;
  color: black;
  margin: 0px;
}

.gallery-text span a {
  background: linear-gradient(304deg,
      #ff8e4f 1.75%,
      #fb035c 4.36%,
      #c51a98 141.36%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gallery-text-1 h3 {
  font-size: 42px;
  line-height: 58px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 15px;
}

.gallery-text-1 p {
  font-size: 20px;
  line-height: 29px;
  font-weight: 400;
  margin: 0;
}

.gallery-text-1 {
  text-align: center;
  padding: 0px 0px 15px 0px;
}

section.gallery-section {
  margin-top: 100px;
}

div#main-3 button {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
}

div#main-3 button img {
  margin-left: 6px;
  filter: invert(10);
  width: 20px;
}

.gallery-main-img {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}

.gallery-img3 img {
  width: 100%;
  border-radius: 10px;
}

#more {
  display: none;
}

section.gallery-section-4 {
  margin: 0px 0px;
}

input.search-box:focus-visible {
  border: 1px solid #d0d5dd !important;

  outline: none !important;
}

.blog-input {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 104px;
}

.blog-main-1::after {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  right: -50px;
  width: 0.98px;
  height: 100%;
  background-color: #d0d5dd;
}

.blog-main-1 {
  position: relative;
}

section.blog-section {
  margin: 100px 0px;
}

.aayur-3 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin: 20px 0px;
}

a.nav-link {
  font-weight: 400 !important;
  color: white !important;
}

.img-radious img {
  width: 67px !important;
}

.para-3 {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 0;
}

.para-3 p {
  font-size: 18px;
  font-weight: 500;
  line-height: 34px;
  margin-bottom: 0;
}

section.about-information {
  margin: 55px 0px 0;
}

.insta-img-3 {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
}

.insta-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin-top: 25px;
}

.insta-button-text {
  color: white;
  font-size: 26px;
  font-weight: 600;
  line-height: 39px;
}

.insta-imgg img {
  width: 37px;
  height: 37px;
}

.main-img h4 {
  color: #000;
  font-weight: 600;
  font-size: 20px;
  width: 100%;
  color: #062550;
}

.main-img h6 {
  width: 85%;
}

.main-img h6 {
  font-size: 14px;
  font-weight: 400;
  color: #062550;
}

h2.freq-ask-qqq.accordion-header button {
  color: #062550 !important;
}

section.bg-img2 .teext2 {
  padding-top: 0;
}

img {
  max-width: 100%;
}

.heading h4 {
  font-size: 52px;
  font-weight: 700;
  line-height: 63px;
}

.heading p {
  font-size: 22px;
  font-weight: 400;
  line-height: 27px;
}

.headingtwo h6 {
  font-size: 24px;
  line-height: 29px;
  font-weight: 700;
}

.headingtwo p {
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
}

.heading3 h6 {
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
}

.manageboth p {
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  margin: 10px 0px;
}

.manageboth p a {
  background: linear-gradient(304deg,
      #ff8e4f 1.75%,
      #fb035c 4.36%,
      #c51a98 141.36%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.heading4 h6,
.heading7 h6,
.heading8 h6,
.heading9 h6 {
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  margin: 0;
  margin-top: 30px;
  margin-bottom: 5px;
}

.heading4 p,
.heading7 p,
.heading8 p,
.heading9 p {
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  margin: 0;
  margin: 20px 0px;
}

.heading5 h6,
.heading6 h6 {
  font-size: 25px;
  line-height: 30px;
  font-weight: 700;
  margin-top: 40px;
}

.heading5 ul li,
.heading6 ul li {
  font-size: 18px;
  line-height: 35px;
  font-weight: 400;
  margin-left: 60px;
}

.heading7 ul li,
.heading8 ul li,
.heading9 ul li {
  font-size: 18px;
  line-height: 35px;
  font-weight: 400;
  margin-left: 60px;
}

.prev-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.next-button-1 button {
  border: none;
  width: 123px;
  height: 54px;
  border-radius: 10px;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
}

.main-aayur {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
}

.related-post h3 {
  margin: 20px 0px;
  font-size: 30px;
  line-height: 36px;
  font-weight: 700;
  color: #021c3f;
}

.related-post {
  margin: 30px 0px;
}

.blogimage img {
  width: 100%;
  max-height: 699px;
  border-radius: 50px;
  height: 100%;
  text-align: center;
  object-fit: cover;
}

.blogimage {
  text-align: center;
  padding-top: 10px;
}

.drop-img {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.main-heading-title h3 {
  font-size: 24px;
  line-height: 22px;
  font-weight: 600;
  margin: 0;
  padding: 20px 0px;
  color: #021c3f;
}

.drop-txt h6 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.main-heading-date h3 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  color: #8099bb;
  margin: 0;
  padding: 10px 0px;
}

.calander-1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
}

.heading_333 h3 {
  margin: 0;
  font-size: 32px;
  font-weight: 600;
  line-height: 37px;
  color: #021c4a;
  padding-bottom: 15px;
}

.calander-1 p {
  margin: 0px;
}

.drop-img-1 img {
  width: 80px;
  height: 80px;
}

.drop-img-1 {
  flex: 0 0 80px;
}

.accordion.blog-list .accordion-item {
  background-color: #ffe9f1 !important;
}

button.accordion-button.blog-btn {
  background-color: transparent !important;
  font-family: Montserrat !important;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.94px;
}

ul.Category-list li {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 30.5px;
  list-style: none;
}

.calander-1.Articles-blog-date p {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.drop-img.art-menu-list {
  margin-bottom: 20px;
}

.drop-img.art-menu-list:last-child {
  margin-bottom: 0px;
}

.terms {
  padding: 80px 0px 105px;
}

.terms-1 h1 {
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  color: #121212;
}

.terms-1 h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  padding-top: 14px;
  padding-bottom: 20px;
  margin: 0px;
}

.terms-1 p {
  font-size: 19px;
  font-weight: 400;
  line-height: 30px;
  color: #1a1a1a;
}

.terms-1 ul li {
  list-style: decimal;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  padding-bottom: 10px;
  color: #1a1a1a;
  margin-left: 20px;
}

.blog-vide o {
  border-radius: 6.69px;
  overflow: hidden;
}

.gallery-main.gallery02 {
  display: none;
}

.gallery-main.gallery02.showitem {
  display: grid;
}



.gallery-main-img.gallery03.showimg {
  display: grid;
}

.open_nav {
  background-color: black;
}

/*================================================ home page ================================================*/
/* nav.navbar.navbar-expand-lg.navbar-light.bg-light.home-nav {
     background-color: #062550 !important;
}
 section.header_bg {
     background: #062550 !important;
}
 select.form-select.form-select-sm {
     width: 110px !important;
     height: 22px;
}
 */
/* .carousel-control-next, .carousel-control-prev {
     display: none !important;
}
 */
.body-main {
  background-color: #f1f1f1;
  color: #0d2444;
}

body {
  color: #0d2444;
}

.catergories_text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.category_main {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 20px;
}

.catergories_img img {
  border-radius: 50%;
}

.catergories_img h2 {
  font-weight: 600;
  line-height: 20px;
  font-size: 16px;
  text-align: center;
}

.catergories {
  margin: 25px 0px;
  padding: 20px;
  background-color: white;
  border-radius: 7px;
}

.catergories_text h4 {
  margin: 0;
}

.occasions-img {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  padding-top: 6px;
}

.occasions-img_3 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

/* .occation-texxt h3{
     margin: -20px 7px;
}
 */
.occation-texxt h3 {
  font-weight: 600;
  line-height: 20px;
  font-size: 16px;
  color: white;
  /* margin: -25px 10px;
     */
}

.occation-texxt h3 {
  position: absolute;
  bottom: 10px;
  left: 6px;
}

.occasions-img_1,
.occasions-img_4,
.occasions-img_5,
.occasions-img_6 {
  position: relative;
  height: 100% !important;
}

.home-page-header nav.navbar.navbar-expand-lg.navbar-light.bg-light.home-nav {
  background: #021C3F !important;
  box-shadow: 0px -1px 1px 0px #0000001a inset;
}

.urdu-txt {
  color: white;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-expand-lg .navbar-nav {
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
}

.occasions-img_6 {
  margin-top: 15px;
}

.Occasions_text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #0d2444;
}

.occasions-main {
  padding: 20px;
  background: white;
  border-radius: 8px;
}

.Occasions_text h4 {
  margin: 0;
  font-size: 28px;
  line-height: 34px;
  font-weight: 700;
}

.wedding-maain {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 35px 0px;
  margin-bottom: 15px;
}

.wedding-bg {
  background-color: white !important;
  margin: 25px 0px;
}

.wedding-text h6 {
  line-height: 17px;
  font-size: 13px;
  font-weight: 500;
}

.wedding-text h4 {
  font-size: 22px;
  font-weight: 800;
  line-height: 27px;
}

.wedding-text p {
  font-size: 12px;
  line-height: 25px;
  font-weight: 600;
}

.wedding-text button {
  border: none;
  background: linear-gradient(2.25deg, #967e51 16.21%, #ce9944 54.05%);
  width: 115.02px;
  height: 36.6px;
  border-radius: 6px;
}

.wedding-text button a {
  font-weight: 500;
  line-height: 17px;
  font-size: 14px;
  color: white;
}

.wedding-text {
  height: 100%;
  place-content: center;
  padding: 56px 21.11px 43px 21.11px;
  border-radius: 10px 0px 0px 10px;
  color: white;
  display: grid;
}

.occasions-img_1 img,
.occasions-img_4 img,
.occasions-img_5 img,
.occasions-img_6 img,
.occasions-main_2 img {
  width: 100%;
  /* height: 100%;
     */
}

.catergories_img {
  text-align: center;
  width: 145px;
}

.catergories_img h2 {
  padding: 17px 5px;
}

.catergories_img h2 {
  padding: 0px 6px;
  padding-top: 17px;
  color: #062550;
}

.catergories_text a,
.Occasions_text a {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  border-bottom: 1px solid;
}

.suggestion-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  gap: 24px;
}

.suggestion {
  margin: 25px 0px;
}

.suggestion-img h4 {
  position: absolute;
  bottom: 22px;
  left: 6px;
  color: white;
}

.suggestion-img {
  position: relative;
}

.service-img, .both-m-div {
  position: relative;
  cursor: pointer;
}

.service_teext h3 {
  color: #021c3f;
  line-height: 22px;
  font-size: 19px;
  font-weight: 500;
  position: absolute;
  top: 20px;
  left: 2px;
  background: #f2c230;
  border-radius: 7px;
  width: 104px;
  height: 32px;
  display: FLEX;
  justify-content: CENTER;
  align-items: CENTER;
  border-radius: 12px 0px 12px 0px;
}

.service-img img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  min-height: 205px;
}

.product-img {
  max-height: 210px;
  max-width: 100%;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  overflow: hidden !important;
  margin-bottom: 10px;
}

.service-img {
  position: relative;
  background-color: white;
  border-radius: 20px !important;
  border: 1.53px solid #dadada;
  box-shadow: 0px 0px 10px 0px #0000001a;
  overflow: hidden;
  padding: 10px;
  height: 100%;
}

.service_text {
  padding: 0px 10px 10px 10px;
}

.service_text h2 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  margin: 0;
  padding-bottom: 5px;
  word-break: break-all;

}

.service_text h4 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  margin: 0;
  padding-bottom: 5px;
  word-break: break-all;
}

.service _text p {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #515b7c;
  margin: 0px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;
}

.service_text h3 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  margin: 0;
  color: #062550;
}

.service_text h3 span {
  font-size: 18px;
  line-height: 21px;
  font-weight: 400;
  color: #a5a5a5;
  text-decoration: line-through;
}

.service_img_main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 22px;
  gap: 20px;
}

.service-main {
  margin: 25px 0px;
}

section.invt_bg {
  background-image: url(../images/backofinvtfrnd.png);
  box-shadow: 0px 0px 30px 0px #d3d1d840;
  margin-top: 0px;
  background-size: 100% 100%;
  height: 100%;
  padding: 50px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: end;
  background-repeat: no-repeat;
}

.invt-img img {
  width: 100%;

  height: 100%;

  max-width: 607px !important;
  max-height: 480px !important;
}

.invt-text {
  height: 100%;
  max-width: 752px;
  place-content: center;
  text-align: center;
  display: grid;
  justify-content: start;
  align-items: center;
}

.invt-text h5 {
  font-size: 65px;
  line-height: 30px;
  font-weight: 600;
  color: white;
}

.invt-text p {
  font-size: 25px;
  line-height: 45px;
  font-weight: 400;
  color: white;
  margin: 0;
  padding-bottom: 20px;
}

.invt-text button {
  background: linear-gradient(328.15deg, #ce9944 2.32%, #ffe1a8 179.84%);
  height: 50px;
  border: none;
  border-radius: 10px;
  margin: 0 auto;
  padding: 0px 20px;
  max-width: 250px;
  width: 100%;
}

.request-q-form input#service_id {
  height: 100% !important;
  width: 100% !important;
  background: transparent !important;
}

.main-banner-img22 img {
  width: 100% !important;
  max-height: 800px !important;
  height: 100%;
}

.featured-type {
  position: absolute;
  background-color: #F2C230;
  padding: 4px 7px;
  border-radius: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 10px;
  z-index: 9;
}

.featured-type h3 {
  font-size: 14px;
  margin: 0px;
  font-weight: 500;
  color: #021C3F;
}

.reveives-sec a:hover {
  color: #12121259;
}


.invt-text button a {
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  color: black;
}

button.carousel-control-next {
  display: none;
}

button.carousel-control-prev {
  display: none;
}

.carousel-indicators [data-bs-target] {
  width: 18px !important;
  height: 18px !important;
  border-radius: 50%;
  background: rgba(224, 224, 224, 1);
  opacity: 1 !important;
  border: none !important;
}

.carousel-indicators .active {
  background: rgba(206, 153, 68, 1) !important;
  width: 22px !important;

  height: 22px !important;
}

.occasions-main_2 .owl-dots {
  bottom: 0;
}



.d-flex .form-control.me-2 {
  width: 750px;
  height: 48px;
  background: var(--New-White, rgba(255, 255, 255, 1)) !important;
  font-size: 13px !important;
  line-height: 16px !important;
  font-weight: 500 !important;
  padding-left: 38px !important;
}

.d-flex .form-control.me-2::placeholder {
  color: #062550 !important;
}

section.header_bg {
  background: #021C3F;
  box-shadow: 0px -1px 1px 0px #0000001a inset;
}

section.wedding-bag {
  background: white;
  margin: 25px 0px;
}

/*==========================================services page================================*/
.jwellers--txt-mmain-img {
  position: relative;
  top: -76px;
  flex: 0 0 180px;
}

.jellow-maan-wedding .accordion-body {
  padding: 10px !important;
}

.fiturs-btn {
  position: absolute;
  top: -50px;
  right: 90px;
  width: 100%;
  max-width: 200px;
  height: 50px;
  background: linear-gradient(336.53deg, #ffe1a8 -6.46%, #ce9944 51.4%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px 20px 0px 0px;
}


.jwellers--txt-mmain-img-txt h1 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
  color: #021c3f;
  margin: 0px;
  padding-bottom: 10px;
}

.fiturs-btn h2 {
  font-size: 25px;
  font-weight: 600;
  color: #ffffff;
  line-height: 32px;
  margin: 0;
}

.jwellers--txt-mmain-img-txt p span {
  padding-right: 10px;
}

.jwellers--txt-mmain-img-txt p {
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  color: #021c3f;
  margin: 0;
  padding-bottom: 8px;
}

.reveives-sec a {
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  color: #12121259;
}

.offer-txt.provider-price h4 {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #062550;
  display: -webkit-box;
  font-size: 22px;
  font-weight: 600;
  line-height: 25px;
  margin: 0;
  overflow: hidden;
  padding-bottom: 0;
  text-overflow: ellipsis;
  word-break: break-all;
}

.offer-txt.provider-price h5 {
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  color: #062550;
  margin: 0px;
}

.offer-txt h4 {
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
  margin: 0;
  padding-bottom: 0px;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reveives-sec {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.jweelers-cart-main {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 6px 31px 0px;
}

.service_text span {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.jwellers--txt-mmain {
  display: flex;
  align-items: flex-start;
  gap: 50px;
}


.star-give {
  display: flex;
}

.star-imgg {
  display: flex;
  align-items: center;
  gap: 6px;
}

.star-imgg h2 {
  margin: 0;
  font-size: 24px;
  line-height: 25px;
  font-weight: 500;
  padding-right: 5px;
}

.jwellers--txt-mmain-img img {
  background: white;
  border-radius: 50%;
  width: 200px;
  height: 180px;
  object-fit: cover;
}

.reveives-sec img {
  rotate: -90deg;
  padding: 0;
  width: 22px;
  margin: 0px 10px;
}


.massage-text {
  background: #062550;
  max-width: 199px;
  height: 60px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.massage-text a {
  font-size: 22px;
  line-height: 24px;
  font-weight: 500;
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.massage-text a img {
  padding-right: 7px;
  width: 42px;
}

/* button.accordion-button .offer-img {


  background: linear-gradient(white, white) padding-box,
    #CE9944 border-box !important;
  border-radius: 20px !important;
  border: 2px solid transparent;
  padding: 6px;
  width: 100%;
  max-width: 100px;
  height: 100%;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 100px;
} */
/* .offers-details ul {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
} */
.provider-list-name {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #062550;
}

.offers-details {
  width: 100%;
  position: relative;
}

.new-main-class-of-all-gallery img {
  max-width: 130px;
  width: 100%;
  height: 130px;
}

.new-main-class-of-all-gallery {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 15px;
}

.offers-details ul li div {
  display: flex;
  align-items: flex-start;
  gap: 4px;
}

.provider-list-name img {
  width: 20px;
}

.offer-img.offer-img-offer-img-new {
  display: flex;
}

.offers-details ul li {
  list-style: none;
}

.offer-img.offer-img-offer-img-new img {
  height: 100%;
  width: 100%;
  max-width: 250px;
  min-height: 250px;
  flex: 0 0 250px;
  background: linear-gradient(#ffffff, #ffffff) padding-box, #062550cf border-box !important;
  border-radius: 20px !important;
  border: 3px solid transparent;
  padding: 3px;
}

.main-new-button-all-div {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0px 15px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.main-new-button-all-div .offer-button button {
  width: 200px;
}

.new-main-class-of-all-gallery img {
  background: linear-gradient(white, white) padding-box, #CE9944 border-box !important;
  border-radius: 20px !important;
  border: 2px solid transparent;
  padding: 3px;
}

.offers-main.offers-main-main-876 {
  padding: 0px !important;
  margin-bottom: 10px;
}

.offers-mainnew-mainhhhh234.accordion-item {
  box-shadow: unset !important;
}

.search-dropdown.search-dropdown-new-mainhh .product-img01 a img {
  width: 120px;
  height: 120px;
}

.search-dropdown.search-dropdown-new-mainhh .product-title a:hover {
  color: black;
}

.search-dropdown.search-dropdown-new-mainhh .product-title a {
  font-size: 13px;
  font-weight: 500;
}

ul.list2.for-res li a {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
}

.ant-tabs.ant-tabs-top.message-min .ant-tabs-nav-list {
  width: 100%;
}


.offers-main-1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 26px;
}

section.request-bag div#nav-tab {
  justify-content: space-around;
  padding-top: 22px;
  background: #eef5ff;
  margin-bottom: 40px;
}



section.request-bag .nav-tabs .nav-link:focus,
.nav-tabs .nav-link.active:hover {
  border-bottom: 4px solid #062550 !important;
}

section.request-bag .nav-tabs .nav-link.active {
  border: navajowhite;
  background: none;
  font-size: 30px !important;
  font-weight: 600 !important;
  line-height: 35px;
  padding-bottom: 17px;
  border-bottom: 4px solid #062550;
  width: 100%;
  max-width: 554px;
}

section.request-bag .nav-tabs .nav-link {
  width: 100%;
  max-width: 554px;
  color: #121212 !important;
  font-size: 30px !important;
  font-weight: 400 !important;
  line-height: 35px;
}

section.request-bag {
  border-radius: 100px 100px 0px 0px;
  background: #f6f7fb;
  margin-top: -196px;
  position: relative;
}

.offer-txt h3 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #515b7c;
}

.offer-txt h5 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: #062550;
}

.offer-txt h5 span {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: var(--Gray, #a5a5a5);
}


body {
  background: #f1f1f1;
}



.offers-main {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  background: white;
  /* box-shadow: 0px 0px 19.14px 0px #0000001a; */
  border-radius: 12px !important;
  padding: 22px 10px 16px 10px;
  width: 100%;
}

li.nav-item:last-child {
  border: none !important;
  justify-content: center;
}

.home-page-header a.nav-link h1 {
  background: #CE9944;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  width: 77px;
  margin: 0;
}

.header_bg li.nav-item {

  border: none !important;
}

.owl-cl .carousel-caption {
  bottom: 150px !important;
  z-index: 2;
}

.ant-layout {
  background: #f6f7fb !important;
}

.hdr-img-upper img {
  position: absolute;
  width: 100%;
  bottom: -10px;
  height: fit-content !important;
}

.owl-cl .live-txt h3 {
  font-weight: 500;
  font-size: 130px;
  line-height: 120px;
  padding-top: 8px;
  color: white;
}

.owl-cl .live-txt h5 {
  background: #CE9944;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 90px;
  line-height: 65px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 20px;
}

.live-txt h4 {
  font-size: 70px;
  font-weight: 600;
  line-height: 65px;
  margin: 0;
  color: white;
  padding-bottom: 20px;
}

.owl-cl .live-txt p {
  font-size: 30px;
  line-height: 33px;
  font-weight: 400;
  padding-top: 23px;
  margin: 0;
}

button.bookk-btm {
  width: 100%;
  height: 50px;
  border: none;
  background: #062550;
  border-radius: 7px;
  margin-top: 23px;
  border: 2px solid #ce9944;
  color: #CE9944;
  max-width: 220px;
}

section.owl-cl .carousel-indicators {
  align-items: center;
  z-index: 2;
}

button.bookk-btm a {
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  color: black;
}

section.owl-cl {
  position: relative;
}

.owl-cl .live-txt {
  text-align: center;
}

section.owl-cl .carousel-item {
  position: relative !important;
}

section.owl-cl .carousel-item img {
  width: 100%;
}

.no-event-maainhh.providers-no img {
  max-width: 500px;
  height: 100%;
  width: 100%;
}

.input-group .btn {
  position: relative;
  z-index: 2 !important;
}

span.icon-event-hh img {
  width: 32px;
  height: 32px;
  filter: invert(1);
}

.no-event-maainhh.providers-no {
  text-align: center;
}

.hdr-img-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 85%;
  height: 700px !important;
  z-index: 1;
  width: 100%;
}

.occasions-main_2 .engagement-owl {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #CE9944;
  height: 100%;
  padding: 0 30px;
}

section.tabbs-section ul#myTab {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: none;
  padding-bottom: 20px !important;
}

section.tabbs-section .nav-tabs .nav-link.active {
  border: none;
  background: #d8d8d8;
  border-radius: 12px !important;
  font-size: 30px !important;
  font-weight: 500 !important;
  line-height: 35px;
  color: #073c51 !important;
}

section.tabbs-section .nav-tabs .nav-link {
  font-size: 30px !important;
  font-weight: 400 !important;
  line-height: 35px !important;
  color: #073c51 !important;
}

.owl-img-main-text {
  color: white;
}

.occasions-main_2 .owl-carousel .owl-stage-outer {
  border-radius: 8px;
}

.engagement-owl.Wedding {
  padding: 0 21px;
}

.owl-img-main-text.Wedding p {
  font-size: 13px;
  line-height: 16px;

  font-weight: 500;
  padding-bottom: 4px;
  margin: 0px;
}

.owl-img-main-text.Wedding h5 {
  font-size: 22px;
  line-height: 27px;
  font-weight: 800;
  margin: 0;
  padding-bottom: 8px;
}

.owl-img-main-text.Wedding h4 {
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 14px;
}

.owl-img-main-text.Wedding button {
  border: none;
  width: 115px;
  height: 36px;
  background: #062550;
  border-radius: 8px;
}

.owl-img-main-text.Wedding button a {
  font-size: 13px;
  line-height: 17px;
  font-weight: 500;
  color: white;
}

.engagement-owl p {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}

.engagement-owl h5 {
  font-size: 32px;
  line-height: 39px;
  font-weight: 800;
}

.engagement-owl h4 {
  font-size: 18px;
  line-height: 21px;
  font-weight: 600;
}

.engagement-owl button {
  border: none;
  width: 166px;
  height: 52px;
  background: #062550;
  border-radius: 8px;
}

.engagement-owl button a {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  color: white;
}

.occa-main .carousel-caption {
  position: absolute;
  right: 0;
  bottom: 1.25rem;
  left: 33px;
  padding-top: 0;
  padding-bottom: 0;
  color: #fff;
  text-align: left;
  top: 113px;
  width: 336px;
}

.occa-main .carousel-indicators .active {
  background: #062550 !important;
}

.wedding-bag .carousel-indicators .active {
  background: #062550 !important;
}

.wedding-bag .carousel-indicators [data-bs-target] {
  background: #e0e0e0;
}

section.bg-img2.home-p {
  background: white;
  padding: 45px 0px;
}

ul.navbar-nav.mx-auto.mb-2.mb-lg-0 {
  align-items: center;
}

.header_bg select#pet-select {
  width: 100%;
  max-width: 109px;
}

.home-page-header {
  padding: 10px 0px;
}

.header_bg form.d-flex {
  position: relative;
}

.home-page-header form.d-flex img {
  position: absolute;
  top: 15px;
  left: 17px;
}

.service-backg .ant-btn:hover {
  background-color: transparent !important;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
  color: #062550 !important;
}

li.ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
  width: 100%;
  height: 48px;
  border-bottom: 1px solid;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
  background-color: #062550 !important;
  color: white !important;
}

.home-page-header ul.navbar-nav.ms-auto.mb-2.mb-lg-0 {
  align-items: center;
}

.home-page-header .ant-btn:hover,
.home-page-header .ant-btn:active {
  background-color: transparent !important;
}

.service-backg {
  position: relative;
}

.request-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
  border-bottom: 1px solid var(--Placeholder-and-Icon, #a2a2b5);
  margin: 25px 0px;
}

.ser-img-back img {
  position: absolute;
  top: 0;
  height: 100%;
}

.gallery-main-img_2 {

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 27px;
}

.gallerymaintext h2 {
  padding-left: 37px;
  padding-bottom: 15px;
  font-size: 26px;
  line-height: 32px;
  font-weight: 500;
}

.gallery-main-img1 {
  width: 100%;
  max-width: 374px;
}

.offers-main-1 button.accordion-button {
  margin: 0;
  width: 100%;
  padding: 0;
  position: relative;
}

.offers-main-1 .accordion-button::after {
  position: absolute;
  bottom: 23px;
  right: 22px;
}

.email-img img {
  width: 25px;
  height: 21px;
  flex: 0 0 25px;
}

.email-img {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 18px;
  padding-bottom: 15px;
}

.email-img h3 {
  margin: 0;
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  color: black;
}

.details-maain-text h2 {
  font-size: 26px;
  line-height: 32px;
  font-weight: 500;
  padding-bottom: 17px;
  margin: 0px;
}

section.request-bag div#nav-contact {
  background: white;
  padding: 30px 2px 115px 40px;
}

.subcatergor p {
  font-size: 20px;
  line-height: 18px;
  font-weight: 400;
  margin: 0;
}

.subcatergor p span {
  font-size: 22px;
  line-height: 26px;
  font-weight: 400;
}

.subcatergor {
  padding: 30px 0px;
}

.location-main {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-bottom: 30px;
}

.location-img img {
  width: 22px;
  height: 25px;
}

.location-img {
  width: 42px;
  height: 42px;
  background: #eef5ff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border-radius: 7px;
}

.location-txt h2 {
  font-size: 24px;
  line-height: 29px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  padding-bottom: 7px;
}

.location-txt p {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  color: var(--Gray, #686868);
  padding: 0;
  margin: 0;
}

.details-maain-text {
  border-bottom: 1px solid var(--Placeholder, #a7a7a7);
  margin-bottom: 10px;
  padding-bottom: 0px !important;
}

.details-maain-text h4 {
  margin: 0;
  padding-bottom: 5px;
  font-size: 23px;
  font-weight: 600;
}

.about-page-main p {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  margin: 0;
}

p.for-m {
  margin-bottom: 8px !important;
}

.business-hours h2,
.about-page-main h2,
.Social-links h2 {
  font-size: 30px;
  line-height: 30px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 15px;
}

.business-hours ul.sunday li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 49px;
  padding-bottom: 20px;
}

.business-hours ul.sunday {
  width: 50%;
}

ul.sunday li p {
  margin: 0px;
}

.colsed-day ul {
  list-style: none;
}

.colsed-day ul li {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
}

li.for-notm p {
  padding: 0px !important;
}

li.for-bold {
  font-weight: 600 !important;
}

.business-hours,
.Social-links,
.about-page-main {
  border-bottom: 1px solid var(--Placeholder, #a7a7a7);
  padding: 15px 0px 19px 0px;
}

.Social-Links-logo {
  display: flex;
  align-items: center;
  gap: 16px;

}

.Social-Links-logo>img {
  cursor: pointer;
}

.requesta-quote {
  max-width: 250px;
  height: 50px;
  width: 100%;
  background: #021c3f;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
  border-radius: 10px;
  margin-top: 60px;
  margin-bottom: 50px;
  padding: 0px 20px;
}

.requesta-quote a {
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  color: #f2c230 !important;
}

.offers-container {
  box-shadow: 0px 0px 19.14px 0px #0000001A;
  padding: 10px;
  border-radius: 15px;
}


.report-button button {
  background: #062550;
  max-width: 250px;
  height: 50px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.report-button {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 30px;
}

.report-button button a {
  font-size: 17px;
  line-height: 27px;
  font-weight: 600;
  color: rgb(255, 255, 255);
}

.occasions-main_2 .owl-dots {
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  /* flex: 0 0 auto;
     */
}

.main-play-logo a img {
  width: 90px;
  height: 30px;
  object-fit: contain;
  border: 0.75px solid #A6A6A6;
  border-radius: 5px;
}

.wedding-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

section.galllery-sec {
  padding: 50px 0px;
}

.occasions-main_2.Wedding h2 {
  text-align: center;
  font-size: 50px;
  font-weight: 600;
  line-height: 60px;
  padding-bottom: 40px;
  color: #0d2444;
}

section.wedding-bg-1 {
  background: white;
}

.wedding-img {
  width: 100% !important;
  height: 100%;
}

.wedding-img img {
  height: 100%;
}

.home-page-header li.nav-item:last-child {
  margin: 0;
  padding: 0;
}

.wrapper {
  width: 109px;
  /* margin: 3% auto;
     */
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown dd,
.dropdown dt,
.dropdown ul {
  margin: 0px;
  padding: 0px;
}

ul.dropdown-menu.show {
  left: -25px;
}

.dropdown dd ul li a span:first-child,
.dropdown dt a span span:first-child {
  background-image: url("https://i.imgur.com/OQiDoZe.png");
  background-repeat: no-repeat;
  width: 16px;
  height: 11px;
  display: inline-block;
  margin: 5px;
  vertical-align: top;
}

.dropdown dt a span {
  cursor: pointer;
  display: block;
  white-space: nowrap;
}

.dropdown dt a img {
  position: relative;
  z-index: 1;
}

.dropdown dt a span span:first-child:before {
  position: absolute;
  content: "";
  width: 15px;
  height: 10px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2) inset;
}

.dropdown dt a span span {
  display: inline-block;
  padding: 0;
}

.dropdown dt a span span:first-child {
  padding: 0;
}

.dropdown dd {
  position: relative;
}

.dropdown a,
.dropdown a:visited {
  color: white;
  text-decoration: none;
  outline: none;
}

.dropdown-menu li .dropdown a:hover {
  color: black !important;
  background: #ffffffa8 !important;
}

.d ropdown dt a:hover,
.dropdown dt a:focus {
  color: #5d4617;
}

.dropdown dt a {
  position: relative;
  display: block;
  padding-right: 0;
  overflow: hidden;
  border: none;
  width: 109px;
}

.dropdown dt a:after {
  content: none;
}

.dropdown dt a:before {
  content: none;
}

.dropdown dd ul {
  background: #f0f2f7;
  /* color: #C5C0B0;
     */
  display: none;
  left: 0px;
  padding: 5px 0px;
  position: absolute;
  width: 120px;
  /* border: 1px solid #ed4267;
     */
  list-style: none;
  max-height: 176px;
  /* overflow-y: scroll;
     */
  top: 0px;
  z-index: 2;
}

li a {
  font-size: 13px;
}

li a span:nth-child(2) {
  line-height: 2em;
}

.dropdown dd ul::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.dropdown dd ul::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.4);
  /*-webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0.5), 1px 0 0 #5cace9 inset, 2px 0 0 #b3d5ee inset;
     border-radius:10px;
    */
}

.dropdown dd ul::-webkit-scrollbar-thumb:window-inactive {
  background: blue;
}

.dropdown span.value {
  display: none;
}

.dropdown dd ul li a {
  padding: 5px;
  display: block;
  font-size: 12px !important;
}

.dropdown dd ul li a:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

dl.dropdown {
  display: inline-block;
  width: 240px;
  margin: -3px 0 0 1px;
}

dl.dropdown span:nth-child(3) {
  color: rgba(0, 0, 0, 0.4);
}

dl.dropdown>span:nth-child(2) {
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}

dl.dropdown span:nth-child(3) {
  float: right;
}

dl.dropdown dt span:nth-child(2) {
  color: white;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
}

dl.dropdown dt span:nth-child(3) {
  display: none;
}

.countryFlag {
  padding: 0;
  background-image: url("https://i.imgur.com/OQiDoZe.png");
  background-repeat: no-repeat;
  display: inline-block;
  height: 11px;
  margin-right: 4px;
  width: 16px;
  cursor: pointer;
  white-space: nowrap;
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: #bfbfc1 #b6b6b6 #969696;
  border-image: none;
  border-radius: 2px 2px 2px 2px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.09);
}

.panel-default>.panel-heading {
  color: #333;
  background-color: white !important;
  border-color: #ddd;

}

.panel.panel-default.main-filter-btn-main-145 {
  padding: 10px 20px;
  border-radius: 0px 0px 25px 25px;
}

button.filter-button-reset.btn.btn-primary {
  justify-content: flex-end;
}

.form-select:focus {
  box-shadow: none !important;
}

.manager h6 {
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  color: #0d2444;
  margin: 15px 0px 7px 0px;
}

.panel-group .panel.pan el-default {

  background: white;
}

select.form-select {
  width: 100% !important;
  padding: 17px 20px;
  box-shadow: none;
}

label.radio-container img {
  padding-right: 10px;
}

.baccolor select.form-select {
  font-size: 15px !important;
  line-height: 17px;
  font-weight: 400;
  background-position: right 20px center;
  border-color: #a7a7a7;
  border-radius: 8px;
}

.distance-radio-con {
  padding-bottom: 23px;
  padding-top: 14px;
  border-bottom: 1.15px solid var(--Stroke, #e6e6e6);
}

.panel-heading h4.panel-title {
  font-size: 18px;
  line-height: 21px;
  font-weight: 600;
}

.panel-heading h4.panel-title img {
  padding-right: 12px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

::selection {
  color: #fff;
  background: #062550;
}

header h2 {
  font-size: 24px;
  font-weight: 600;
}

header p {
  margin-top: 5px;
  font-size: 16px;
}

.price-input {
  width: 100%;
  display: flex;
  margin: 0px;
  padding-bottom: 11px;
  margin-bottom: 10px !important;
  border-bottom: 1.13px solid var(--Stroke, #e6e6e6);
}

.price-input .field {
  display: flex;
  width: 100%;
  height: 45px;
  align-items: center;
}

.field input {
  width: 100%;
  height: 100%;
  /* outline: none;
     */
  font-size: 15px;
  /* margin-left: 12px;
     */
  border-radius: 5px;
  /* text-align: center;
     */
  line-height: 17px;
  font-weight: 400;
  -moz-appearance: textfield;
  border: none;
}

input.input-max {
  text-align: end;
  outline: none;
}

input.input-min {
  outline: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.price-input .separator {
  width: 130px;
  display: flex;
  font-size: 19px;
  align-items: center;
  justify-content: center;
}

.slider {
  height: 5px;
  position: relative;
  background: #ddd;
  border-radius: 5px;
}

.slider .progress {
  height: 100%;
  left: 25%;
  right: 25%;
  position: absolute;
  border-radius: 5px;
  background: #062550;
}

.range-input {
  position: relative;
}

.range-input input {
  position: absolute;
  width: 100%;
  height: 5px;
  top: -5px;
  background: none;
  pointer-events: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="range"]::-webkit-slider-thumb {
  height: 17px;
  width: 17px;
  border-radius: 50%;
  background: #062550;
  pointer-events: auto;
  -webkit-appearance: none;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
}

input[type="range"]::-moz-range-thumb {
  height: 17px;
  width: 17px;
  border: none;
  border-radius: 50%;
  background: #062550;
  pointer-events: auto;
  -moz-appearance: none;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
}

.panel-group {
  margin-bottom: 0px !important;
}

.radio-container-maain {
  padding-top: 10px;
  padding-bottom: 10px;
  /* border-bottom: 1px solid black;
     */
  /* border-bottom: 1.13px solid var(--Stroke, #e6e6e6); */
}

.first-bg .ant-col.ant-col-24 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

/* Style for the radio button container */
.radio-container {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 16px;
}

/* Hide the default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Style for the custom radio button */
.radio-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border-radius: 50% !important;
  border: 1.5px solid #021c3f;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input~.radio-checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a green border */
.radio-container input:checked~.radio-checkmark {
  background: linear-gradient(white, white) padding-box,
    #CE9944 border-box !important;
  border-radius: 50% !important;
  border: 2px solid transparent;
}

/* Create the radio indicator (hidden when not checked) */
.radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the radio indicator when checked */
.radio-container input:checked~.radio-checkmark:after {
  display: block;
}

/* Style the radio indicator (a white dot) */
.radio-container .radio-checkmark:after {
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #CE9944;
}

.event-type-date p {
  text-align: end;
}

.service_img_main {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
}

/* .container {
  max-width: 1686px;
  width: 100%;
} */
.request-bag .accordion-body ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  column-gap: 15px;
  row-gap: 7px;
}

.request-bag .accordion-body ul li img {
  padding-right: 6px;
  width: 100%;
  height: 19px;
  max-width: 24px;
}

.request-bag .accordion-body ul li {
  padding: 7px 8px;
  border-radius: 13px;
  background: #f6f6f6;
  font-size: 16px;
  line-height: 25px;
  font-weight: 400;
}

.offer-button {
  display: flex;
  justify-content: end;
  align-items: center;
  margin: 16px 0px;
  gap: 14px;
}

.main-new-button-all-div .offer-txt h4 {
  font-size: 22px;
  line-height: 25px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 0px;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #062550;
}

.chech-p {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.offer-button button {
  height: 50px;
  border: none;
  border-radius: 7px;
  padding: 0px 10px;
  width: 100%;
  max-width: 200px;
}

button.offer-button1 {
  background: #dde4ff;
  transition: .25s ease-in-out;
}

button.offer-button2 {
  background: #062550;
  color: white;
  transition: .25s ease-in-out;
}

button.offer-button1 a {
  color: var(--Icon, #515b7c);
}

button.offer-button2 a {
  color: white;
}

.offer-button button a {
  font-size: 14px;
  line-height: 23px;
  font-weight: 500;
  color: #F2C230 !important;
}

.gallery-main-img1 img {
  border-radius: 30p x !important;
  width: 100%;
  height: 100%;
  max-width: 374px;
  max-height: 337px;
  object-fit: contain;
}

.request-bag .accordion-item {
  border-radius: 12px !important;
  box-shadow: 0px 0px 19.16px 0px #0000001a;
}

.request-bag button.accordion-button {
  border-radius: 12px !important;
  padding: 0px;
}

.Offerings-img .main-accor-div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
}

button.offer-button1 a {
  color: black !important;
}

.request-bag .accordion-item {
  height: fit-content;
}

.header_bg ul.dropdown-menu.show li:hover a {
  background: #0000006b;
  color: white;
  transition: 0.25s ease-in-out;
}

.header_bg ul.dropdown-menu.show li a {
  padding: 20px;
}

button.offer-button1:hover {
  border: 1px solid #F2C230;
  background: #F2C230 !important;
  color: #062550 !important;
}

button.offer-button2:hover {
  border: 1px solid #F2C230;
  background: #F2C230 !important;
  color: #062550 !important;
}

.model-form.new {
  padding: 67px 67px 27px 67px;
}

.abouut-uss-main {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.abt-us {
  background-color: white;
  padding: 50px 0px;
}

body#body-bg {
  background: #f6f7fb;
}

.abouut-us-txxt {
  padding-right: 131px;
  text-align: justify;
}

.abouut-us-txxt h3 {
  font-size: 46px;
  font-weight: 600;
  line-height: 27px;
  margin: 0;
  padding-bottom: 20px;
  color: #0d2444;
}

.abouut-us-txxt p {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: #0d2444;
}

.nosub-ccc {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-tabs.ant-tabs-top.message-min.max-w-for .ant-tabs-tab.ant-tabs-tab-active div {
  color: #ffbf00 !important;
}

.abouut-us-img img {
  width: 100%;
  height: 100%;
}

.no-noti-data h4 {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  padding-top: 100px;
}

.cat-taxxts h4 {
  font-size: 30px;
  font-weight: 600;
  line-height: 39px;
  padding-bottom: 20px;
  margin: 0;
  color: #0d2444;
}

.catter-maain-divvv {
  padding: 35px 0px 10px;
}

.birthday-new {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
}

.birthday-new h4 {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  color: white;
  z-index: 20;
  padding: 0px 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.wedding-main.category-imgs .birthday-new h4 {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  color: white;
  z-index: 20;
}

.birthday-new::before {
  position: absolute;
  content: "";
  background: linear-gradient(180.31deg, rgba(25, 48, 81, 0) 55.54%, #021c3f 95.88%, #021c3f 125.69%);
  top: 0;
  bottom: 0px;
  left: 0;
  right: 0;
  z-index: 1;
}

.view-all-items {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  height: 225px;
  transition: .30s ease-in-out;
  background: linear-gradient(180.31deg, rgb(24 48 81 / 84%) 24.54%, #021c3f 84.88%, #021c3f 114.69%)
}

.view-all-items h4 {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

/* .ant-select-single .ant-select-selector .ant-select-selection-search input.ant-select-selection-search-input {
  background-color: #fff !important;
} */

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #d9d9d9 !important;
  border-right-width: 1px;
  box-shadow: none;
}

.ant-select.button-firsttt.ant-select-single.ant-select-show-arrow span.ant-select-arrow {
  color: #000000;
}


.panel-group {
  margin-bottom: 20px;
  position: sticky;
  top: 0px;
}

h4.panel-title a {
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
}

h4.panel-title a img {
  padding-right: 12px;
}

.short-by-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

span.ant-select-selection-search input {
  background: transparent !important;
}

.ant-form-item-control-input-content input {
  background: transparent !important;
}

.ant-picker-input>input {
  background: transparent !important;
}

.loaction-foe-all-67 {
  display: flex;
  align-items: center;
}

input#budget,
input#date {
  background: transparent !important;
}

/* .result-and-shot-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 33px;
} */
.main-service-page-55588 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.result-and-shot-div {
  padding-top: 30px;
}

/* Style the dropdown button */
.short-by-div .dropbtn {
  background-color: #062550;
  color: white;
  /* padding: 12px;
     */
  font-size: 16px;
  border: none;
  cursor: pointer;
  width: 170px;
  height: 48px;
  line-height: 19px;
  font-weight: 400;
  border-radius: 7px;
  position: relative;
}

/* Style the dropdown content (hidden by default) */
.short-by-div .dropdown-content {
  display: none;
  position: absolute;
  background-color: #062550;
  min-width: 164px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10;
  left: 4px;
  top: 50px;
  border-radius: 7px;
}

/* Style the links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.gmail-box .ant-form-item-explain-error {
  position: absolute;
}

.main-new-color-arrayhh {
  display: flex;
  align-items: center;
}

h4.main-new-header-class-for-all {
  font-size: 19px;
  font-weight: 600;
  line-height: 25px;
}

.ant-form-item-control-input-content .react-tel-input input.form-control.invalid-number {
  margin-bottom: 10px !important;
}

button.ant-btn.ant-btn-primary.float-right.float-right-main-new-send-button {
  background: #062550;
  color: white;
  border: none;
  width: 100%;
  height: 55px;
  font-size: 22px;
  line-height: 24px;
  font-weight: 600;
  border-radius: 60px !important;
}

.ant-form-item.username.new-text-area-main {
  margin-top: 24px;
}

.gmail-box input[type="text"] {
  background-color: #e8f0fe !important;
  margin-left: 35px !important;
}

.owl-carousel.owl-theme.owl-loaded.owl-drag .owl-stage {
  margin-bottom: 10px;
}

input.form-control.invalid-number {
  background: white !important;
}

h4.modal_title_cls.mb-2.text-center-main-new {
  justify-content: center;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #0000006e;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #062550;
}

/* -----------------------------------
--------------------------------------- */
.ser-img-back.terw img {
  height: 503px;
  bottom: 0 !important;
  top: 115px;
  width: 100%;
}

.request-img-bg {
  background-image: url(../images/request-img.png);
  height: 502px !important;
}

.requ-teext-mm h2 {
  margin: 0;
  font-size: 50px;
  font-weight: 600;
  line-height: 60px;
  color: white;
  z-index: 200;
}

.requ-teext-mm {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

div#rc-tabs-0-panel-Offerings {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
}

select.form-select.rqtxxt {
  height: 70px;
  padding: 16px 50px !important;
  background-position: right 50px center;
  background-size: 20px;
  font-size: 22px;
  font-weight: 500;
  color: #062550;
  background-color: #f6f7fb;
  border: none;
  border-radius: 20px;
  margin-bottom: 43px;
  opacity: 50%;
}

/* @import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css);
 @import url('https://fonts.googleapis.com/css?family=Roboto');
 */
h2 {
  font-family: "Roboto", sans-serif;
  font-size: 26px;
  line-height: 1;
  margin-bottom: 0;
}

p {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
}

.uploader {
  display: block;
  clear: both;
  margin: 0 auto;
  width: 100%;

  label {
    float: left;
    clear: both;
    width: 100%;
    padding: 2rem 1.5rem;
    text-align: center;
    background: #fff;
    border-radius: 7px;
    border: 3px solid #eee;
    transition: all 0.2s ease;
    user-select: none;

    &:hover {
      border-color: theme;
    }

    &.hover {
      border: 3px solid theme;
      box-shadow: inset 0 0 0 6px #eee;

      #start {
        i.fa {
          transform: scale(0.8);
          opacity: 0.3;
        }
      }
    }
  }

  #start {
    float: left;
    clear: both;
    width: 100%;

    &.hidden {
      display: none;
    }

    i.fa {
      font-size: 50px;
      margin-bottom: 1rem;
      transition: all 0.2s ease-in-out;
    }
  }

  #response {
    float: left;
    clear: both;
    width: 100%;

    &.hidden {
      display: none;
    }

    #messages {
      margin-bottom: 0.5rem;
    }
  }

  #file-image {
    display: inline;
    margin: 0 auto 0.5rem auto;
    width: auto;
    height: auto;
    max-width: 180px;

    &.hidden {
      display: none;
    }
  }

  #notimage {
    display: block;
    float: left;
    clear: both;
    width: 100%;

    &.hidden {
      display: none;
    }
  }

  .progress {
    appearance: none;
    display: inline;
    clear: both;
    margin: 0 auto;
    width: 100%;
    max-width: 180px;
    height: 8px;
    border: 0;
    border-radius: 4px;
    background-color: #eee;
    overflow: hidden;
  }

  .progress[value]::-webkit-progress-bar {
    border-radius: 4px;
    background-color: #eee;
  }

  .progre ss[value]::-webkit-progress-value {
    background: linear-gradient(to right, darken(theme, 8%) 0%, theme 50%);
    border-radius: 4px;
  }

  .progress[value]::-moz-progress-bar {
    background: linear-gradient(to right, darken(theme, 8%) 0%, theme 50%);
    border-radius: 4px;
  }

  input[type="file"] {
    display: none;
  }

  div {
    margin: 0 0 0.5rem 0;
    color: dark-text;
  }

  .btn {
    display: inline-block;
    margin: 0.5rem 0.5rem 1rem 0.5rem;
    clear: both;
    font-family: inherit;
    font-weight: 700;
    font-size: 14px;
    text-decoration: none;
    text-transform: initial;
    border: none;
    border-radius: 0.2rem;
    outline: none;
    padding: 0 1rem;
    height: 36px;
    line-height: 36px;
    color: #fff;
    transition: all 0.2s ease-in-out;
    box-sizing: border-box;
    cursor: pointer;
  }
}

section.request-write-page {
  background: #f6f7fb;
  border-radius: 100px;
  position: relative;
  margin-top: -92px;
  padding-top: 70px;
}

.request-q-form {
  background: white;
  padding: 30px;
  border-radius: 50px;
}

.rqtxt-1 h2 {
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
  margin: 0;
  padding-bottom: 10px;
}

.rqtxt-1 p {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  color: #686868;
}

.rqtxt-1 {
  margin-bottom: 21px;
}

.seerviice h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  padding-bottom: 16px;
  margin: 0px;
}

.seerviice .slider {
  margin-top: 20px;
}

.rqtxt-3 .price-input {
  border: none !important;
}

.rqtxt-3 .slider .progress {
  background-color: #097aff;
}

.rqtxt-5.seerviice h2 {
  /* margin-bottom: 19px;
     */
  /* display: flex;
     */
  margin: 0;
  padding-bottom: 9px;
}

.rqtxt-5.seerviice {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  /* gap: 200px;
     */
  width: 35%;
  justify-content: space-between;
}

.check-button.maaain label {
  font-size: 24px;
  font-weight: 500;
  line-height: 31px;
  color: black;
  padding-left: 26px;
}

.check-button.maaain .form-check-input[type="checkbox"] {
  border-radius: 4px;
  width: 34px;
  height: 34px;
  margin: 0;
}

.check-button.maaain .form-check-input {
  border: 2px solid #a5a5a5;
}

.check-button.maaain .form-check-input:checked {
  background-color: #f2c230;
  border-color: #f2c230 !important;
  box-shadow: none !important;
  outline: none !important;
}

.check-button.maaain .form-check-input:focus {
  box-shadow: none;
  outline: none;
}

.rqtxt-7.seerviice {
  display: flex;
  gap: 30px;
  margin-bottom: 43px;
}

.rqtxt-7.seerviice input.form-control {
  border: none !important;
  box-shadow: none;
  background: transparent !important;
}

.rqtxt-7.seerviice .input-group-text {
  background: #f6f7fb;
  height: 70px;
  border-radius: 20px;
  width: 100%;
}

.rqtxt-7.seerviice .form-c heck-input:checked {
  background-color: #f2c230;
  border-color: #f2c230;
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.rqtxt-7.seerviice .form-check-input[type="radio"] {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 2px solid #a5a5a5;
  margin-right: 20px;
  box-shadow: none;
  outline: none;
}

.dane-flr {
  display: flex;
  gap: 30px;
}

.rqtxt-9.seerviice textarea#floatingTextarea2 {
  height: 291px !important;
  width: 100% !important;
  background: #f6f7fb !important;
  border-radius: 30px !important;
  border: none !important;
}

.rqtxt-10 label {
  float: unset;
}

.rqtxt-10 label {
  float: unset;
  margin-top: 43px;
  height: 263px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rqtxt-9 .form-floating>label {
  top: 24px;
  left: 50px;
  font-size: 22px;
  font-weight: 500;
  color: #062550;
  opacity: 50%;
}

.rqtxt-9 .form-floating>.form-control:focus~label {
  display: none;
}

.no-quotation {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 356px 0;
}

.main-no-q {
  background: white;
  border-radius: 45px;
  width: 749px;
  height: 755px;
}

.img-no-q {
  text-align: center;
}

.no-q-txt {
  text-align: center;
}

.no-q-txt h2 {
  font-size: 33px;
  font-weight: 500;
  line-height: 23px;
  padding-bottom: 23px;
  margin: 0;
}

.img-no-q img {
  padding-bottom: 49px;
}

.no-q-txt p {
  font-size: 23px;
  line-height: 28px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  padding-bottom: 21px !important;
}

.main-quotation-detailss form.search-butnn {
  background: #f6f7fb;
  box-shadow: 0px 0px 56.35px 0px #d3d1d840;
  border-radius: 20px;
  padding: 16px 50px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  height: 70px;
}

.quotation-sub-1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}

.quotation-sub-2 {
  display: flex;
  gap: 26px;
}

.quotation-sub ol.main-oll {
  list-style-type: disc;
  /* display: grid;
  grid-template-columns: repeat(4, 1fr); */
  padding: 7px 0px;
  background: #ffffff;
  border-radius: 20px;
  row-gap: 30px;
  margin: 0px !important;
  padding-left: 20px;
}

.quotation-sub-img .ant-image .ant-image-mask {
  border-radius: 50%;
}

.quotation-sub.quotation-sub-ok {
  border: 2px solid #f3f3f3;

  box-shadow: 0px 0px 24px 0px #0000001a;
}

/* .quotation-sub.quotation-sub-ok ol.main-oll {
  background: #f9f9fc85;
} */

.ant-tabs.ant-tabs-top.budget-main-hddr .ant-tabs-tab {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px !important;
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  color: #7A797A;
  padding: 22px 0px;
}

.budget-main {
  padding-bottom: 0px !important;
  min-height: 550px;
}

.venue-main-immg img {
  width: 80px !important;
  height: 80px !important;
  object-fit: cover;
  border-radius: 10px;
}

.ant-tabs.ant-tabs- top.budget-main-hddr .ant-tabs-tab.ant-tabs-tab-active {
  background: #062550;
  border-radius: 24px;
  border: none;
  outline: none;
  font-weight: 500 !important;
}

.ant-tabs.ant-tabs-top.budget-main-hddr .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #F2C230 !important;
}

.ant-tabs.ant-tabs-top.budget-main-hddr .ant-tabs-nav-list {
  background: #EEF5FF !important;
  border-radius: 10px;
  width: 100%;
}

.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  display: none;
}

.quotation-sub ol.main-oll li {
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
}

.quotation-sub-txt h4 {
  font-size: 22px;
  line-height: 34px;
  font-weight: 600;
  padding-bottom: 0px;
  margin: 0;
}

.quotation-sub-txt h6 {
  font-size: 20px;
  line-height: 26px;
  margin: 0;
  font-weight: 400;
  padding-bottom: 3px;
}

.quotation-sub-txt p {
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  color: #757575;
}

.quotation-sub-txt {
  margin-top: 4px;
}

.quotation-sub-price h2 {
  font-size: 24px;
  line-height: 44px;
  font-weight: 600;
  margin: 0;
  margin-top: 4px;
}

.quotation-sub {
  background: #f9f9fc;
  width: 100%;
}

.main-quotation-detailss input[type="text"] {
  border: none;
  background: none;
  margin-left: 12px;
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
}

.main-quotation-detailss form.search-butnn button img {
  border: none !important;
  /* background: none;
     */
  width: 30px;
  height: 30px;
}

.main-quotation-detailss form.search-butnn button {
  border: none;
  background: none;
}

.event-list-hdr h3 {
  margin: 0;
  padding: 15px 0px;
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
}

.no.service-page {
  font-size: 24px;
  line-height: 25px;
  font-weight: 500;
  color: #062550;
  padding: 15px 0px;
  text-align: center;
}

.event-list-hdr {
  position: relative;
}

.event-list-budget {
  padding: 20px 20px;
  background: white;
  border-radius: 50px 50px 0px 0px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;

  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
}

.edit-and-dlt-bttn {
  display: flex;
  align-items: center;
  gap: 10px;
}

.event-list-budget-dis {
  border: 1px solid #ededed;
  box-shadow: 0px 0px 30px 0px #d3d1d840;
  border-radius: 12px;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
}

.add-sign-new {
  position: sticky;
  bottom: 0px;
  top: 100%;
  right: 0;
  width: 70px;
  left: 100%;
  height: 70px;
  border-radius: 50%;
  background: #021c3f;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: .20s ease-in-out;
}

.main-add-new-event-bttnn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;
  border-radius: 20px;
  background: white;
  box-shadow: 0px 8.3px 62.24px 0px #00000033;
}

.main-add-new-event-bttnn button {
  border-radius: 12px;
  display: grid;
  place-content: center;
  /* background: #F6F7FB; */
  padding: 20px;
  border: none;
  box-shadow: none;
  height: 100%;
  width: 100%;
}

.main-add-new-event-bttnn button span.anticon.anticon-plus-circle {
  font-size: 75px;
  color: #021c3f;
}

.main-add-new-event-bttnn button p {
  font-size: 35px;
  font-weight: 600;
  margin: 0;
  line-height: 29px;
  padding-top: 29px;
  color: #021c3f;
}

.event-type-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.budget-and-guest {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #62a4df33;
  border-radius: 0px 0px 11px 11px;
  padding: 18px 20px;
}

.both-m-div {
  padding: 15px;
  padding-top: 25px;
}

.featured-type.featured-type-main-hfuashf {
  top: 0;
  left: 0;
  width: fit-content;
  height: 25px;
}

.featured-type.featured-type-main-hfuashf h3 {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}

.event-type p,
.event-type-date p {
  font-size: 16px;
  line-height: 18px;
  font-weight: 300;
  margin: 0;
}

.event-type h3,
.event-type-date h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  margin: 0;
}

.panel.panel-default.main-filter-btn-main-145 {
  background: white;
  margin-bottom: 20px;
  overflow: scroll;
  border: 1px solid rgb(220, 220, 220);
  scrollbar-width: none;
}

.event-location {
  color: #062550;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

button.ant-btn.ant-btn-default.dleltebutton-image-22 {
  width: 38px;
  height: 38px;
  border-radius: 999px;
  border: none;
  background: #021c3f;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  color: #f2d377;
  border: 1px solid #021c3f;
}

button.ant-btn.ant-btn-default.dleltebutton-image-22 span.anticon.anticon-delete {
  font-size: 17px;
}

h3.jod-price-disc\. {
  display: flex;
  align-items: center;
  gap: 12px;
}

.budget-oo h2,
.bud-guest h2 {
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
}

.budget-oo h2 span,
.bud-guest h2 span {
  font-weight: 500;
}

.event-location h2 {
  margin: 0;
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  padding: 0px 8px;
  width: 100%;
  max-width: 456px;
  word-break: break-all;
}

.bgtt-listt ul#pills-tab {
  justify-content: space-between;
  padding-bottom: 20px !important;
}

.bgtt-listt ul#pills-tab li button {
  height: 74px;
  width: 827px;
  border-radius: 20px;
}

.bgtt-listt .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background: #062550;
  font-size: 30px !important;
  line-height: 18px !important;
  font-weight: 500 !important;
  color: #f2c230 !important;
}

.bgtt-listt .nav-link {
  font-size: 30px !important;
  line-height: 18px !important;
  color: #7a797a !important;
  font-weight: 400 !important;
}

.bgtt-listt .tab-content>.active {
  display: block !important;
  opacity: 2;
  height: 1330px;
  scrollbar-width: none;
  overflow-y: scroll;
}

.bgtt-listt div#accordionPanelsStayOpenExample {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 22px;
}

.bgtt-listt .accordion-button::after {
  top: 20px !important;
  position:
    absolute;
  margin: 0px !important;
  left: 31px !important;
  width: 28px !important;
  height: 28px !important;
  background-size: 100%;
}

.bgtt-listt button.accordion-button {
  padding: 20px;
  padding-left: 71px;
  color: #021c3f !important;
  background: #eef5ff !important;
  border-radius: 20px 20px 0px 0px !important;
  justify-content: space-between !important;
  padding-right: 30px;
}

.bgtt-listt button.accordion-button h2 {
  margin: 0;
  font-size: 26px;
  line-height: 18px;
  font-weight: 500;
}

.bgtt-listt button.accordion-button h1 {
  margin: 0;
}

.bgtt-listt .accordion-header {
  margin: 0;
}

.bgtt-listt .accordion-body {
  padding: 20px 30px 24px 30px !important;
  background: white;
  border-radius: 0px 0px 20px 20px;
}

.bgtt-listt button.accordion-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.venue-img {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}

.venue-lalsion-1 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 12px !important;
  /* cursor: pointer; */
}

/* .venue-main-immg {
  width: 72px;
  height: 60px;
} */

.venie-m-txt h2 {
  font-size: 20px;
  line-height: 18px;
  font-weight: 400;
  margin: 0;
  padding-bottom: 10px;
}

.venie-m-txt h3 {
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  margin: 0;
  color: black;
}

.venue-cost h3 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  color: #062550;
  margin: 0;
  padding-bottom: 5px;
}

.venue-cost p {
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;
  color: #021c3f;
  margin: 0;
}

.venue-cost {
  text-align: right;
}

.vensu-teext h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: #515b7c;
  padding: 10px 0px;
  margin: 0;
  padding-bottom: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.vensu-teext h4 span a {
  font-weight: 500;
  color: #073c51;
  line-height: 18px;
  font-size: 16px;
}

.venue-lalsion {
  padding: 20px 29px 16px 20px;
  box-shadow: 0px 0px 12px 0px #0000001a;
  position: relative;
  border-radius: 12px;
  margin-bottom: 20px;
  height: 100%;
}


.main-budgut-444 input#event_id {
  background: transparent !important;
}

.venie-m-txt h3 span {
  color: #a5a5a5;
  margin-right: 10px;
}

.venue-beffo {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 41px;
  height: 41px;
  background: #f0f1f5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px 0px 12px 0px;
}

.bgtt-listt .accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
  border: 1px solid #eef5ff;
  border-radius: 20px !important;
}

.venue-lalsion.enue-hh {
  margin: 20px 0px;
}

.ant-form-item.username.main-for-67 input#budget {
  border: none;
}

.add-iemes {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-items-1 button {
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.add-items-1 button h3 {
  font-size: 20px;
  line-height: 21px;
  font-weight: 400;
  color: #062550;
  margin: 0;
  padding-left: 6px;
}

.add-items-1 button h2 {
  margin: 0;
}

.add-price {
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  color: #7a797a;
}



.search-buttton {
  display: flex;
  align-items: center;
  gap: 16px;
}

.search-buttton h2 {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
  color: white;
}

.search-buttton {
  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: 70px;
  box-shadow: 0px 0px 21.07px 0px #00000026;
  background: #11345f;
  padding: 11px 21px;
}

.search-buttton button {
  background: none;
  border: none;
}

.sarch-main-button {
  display: flex;
  align-items: center;
  gap: 14px;
  padding-top: 43px;
  padding-bottom: 37px;
}

.search-buttton.m3-hht {
  background: #eef5ff !important;
}

.search-buttton.m3-hht h2 {
  color: #121212;
  font-size: 19px;
  line-height: 22px;
  font-weight: 400;
  width: 177px;
}

.message-mian {
  margin-top: 40px !important;
  padding-bottom: 10px !important;
}

.class-budget {
  margin-bottom: 20px !important;
}

.remamber-main {
  padding: 14px 20px;
  border: 1px solid #021c3f1a;
  box-shadow: 0px 2px 7px 0px #0000000d;
  background: white;
  display: flex;
  align-items: baseline;
  gap: 10px;
  margin-top: 30px;
  border-radius: 10px;
  cursor: pointer;
}

.remamber-txt-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 9px;
}

.remamber-txt-2 h2 {
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  margin: 0;
}

.remamber-img img {
  width: 25px !important;
  height: 25px !important;
}

.remamber-txt-2 p {
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
  color: black;
  margin: 0;
}

.remamber-para {
  font-size: 18px;
  color: #515b7c;
  line-height: 24px;
  font-weight: 400;
  padding-bottom: 10px;
}

.mark-as-done {
  font-size: 18px;
  line-height: 21px;
  color: #062550;
  font-weight: 400;
}

.remamber-img {
  width: 50px !important;
  height: 42px !important;
}

a.sidebar-btn svg {
  rotate: 90deg;
}

.bgtt-listt.measge-maian {
  background: white;
}

.remamber-img img {
  width: 42px !important;
  height: 42px !important;
}

.ant-tabs.ant-tabs-top.budget-main-hddr .accordion {
  /* display: grid; */
  /* grid-template-columns: repeat(1, 1fr); */
  margin: 10px 0px;
}



.meass-inbox-main {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ebebeb;
  box-shadow: 0px 2px 7px 0px #0000000d;
  gap: 23px;
  margin-top:
    10px;
}

.meas-inbox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.meas-name-tie h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  margin: 0;
}

.meas-name-tie p {
  font-size: 14px;
  line-height: 18px;
  color: #515b7c;
  font-weight: 500;
  margin: 0;
}

.time-mass h3 {
  color: #8f8f8f;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  margin: 0;
  padding-bottom: 13px;
}

.time-mass span {
  width: 35px;
  height: 35px;
  color: white;
  background: #021c3f;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
}

.massage-count {
  text-align: -webkit-right;
}

.measage-img-maan {
  width: 91px !important;
  height: 84px;
}

.chat_1-maa {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat_1-maa p {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: #121212;
  margin: 0;
}

.chat_1-maa h2 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  margin: 0;
  padding-bottom: 3px;
}

.massage-chat-main h3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 13px;
  line-height: 15px;
  font-weight: 400;
  color: #939393;
  margin: 0;
}

.img-chat {
  flex: 0 0 50px !important;
}

.users-chat-massage {
  display: flex !important;
  padding-bottom: 15px;
  border-bottom: 1px solid #dddddddd;
  margin-top: 15px;
  gap: 10px;
}

.users-chat-massage {
  display: flex;
  border-bottom: 1px solid #dddddddd;
  margin-top: 5px;
  gap: 10px;
  cursor: pointer;
  padding: 8px 11px;
  border-radius: 8px;
}

form.search-chat {
  height: 45px;
  background: white;
  border-radius: 12px;
  padding: 12px;
  display: flex !important;
  align-items: center;
  margin-bottom: 24px;
  border: 1px solid #737373;
  box-shadow: 0 0 1em 0 rgb(2 28 63 / 18%);
}

form.search-chat button {
  border: none;
  background: none;
  padding: 0;
}

form.search-chat button img {
  width: 20px;
  height: 20px;
}

form.search-chat input {
  border: none !important;
  padding-left: 8px;
  color: #737373;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  outline: none;
  width: 100%;
}

.main-chat-div-1 {
  padding: 10px;
  margin-top: 40px;
  border-radius: 20px;
  background: white;
  height: 93%;
  margin-bottom: 20px;
}

.users-chat-massage-maain {
  height: 100%;
  max-height: 650px;
  overflow-y: scroll;
  scrollbar-width: none;
}

.main-chat-wtp {
  /* padding: 24px; */
  /* margin-top: 40px; */
  background: white;
  /* border-radius: 50px 50px 0px 0px; */
  height: 650px;
  overflow-y: scroll;
  scrollbar-width: none;
}

.chat-day h2 {
  margin: 0;
  box-shadow: 0px 0px 43.71px 0px #d3d1d840;
  width: 88px;
  height: 3 5px;
  padding: 8px 20px;
  font-size: 17px;
  left: 17px;
  font-weight: 500;
  margin-bottom: 20px;
}

.chat-day {
  display: flex;
  justify-content: center;
  align-items: center;
}

.msg-1 {
  text-align: end;
  padding-top: 20px;
}

.msg-1 h3 {
  border-radius: 20px 0px 20px 20px;
  background: #021c3f;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 50%;
  margin: 0px;
  margin-left: auto;
  padding: 15px 18px;
  color: white;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  word-break: break-all;
  margin-bottom: 7px;
}

.profile-img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.msg-1 p {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  margin: 0;
}

.msg-2 {
  display: flex;
  padding-top: 20px;
  gap: 10px;
  cursor: pointer;
}

.wedding-main.category-imgs.active {
  border: 3px solid #d25a23 !important;
  border-radius: 25px !important;
  transform: scale(1.01);
  padding: 1px;
}

.wedding-main.category-imgs {
  border: 4px solid transparent !important;
}

.ant-modal.tab_modal.main-otp-model .ant-modal-content {
  padding: 0px !important;
}

.prof-chat h2 {
  margin: 0;
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  padding-bottom: 5px;
}

.chat-txxt {
  width: 100%;
}

.img-chat img {
  flex: 0 0 50px !important;
}

.profile-img {
  flex: 0 0 50px;
}

.profile-img img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.profile-img {
  flex: 0 0 35px;
}

.prof-chat h4 {
  box-shadow: 0px 0px 43.71px 0px #d3d1d840;
  padding: 15px 18px;
  border-radius: 0px 20px 20px 20px;
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 7px;
  width: -moz-fit-content;
  word-break: break-all;
  background: #021c3f36;
  width: 100%;
  max-width: 400px;
  color: #021c3f;
}

.prof-chat h4:last-child {
  margin-bottom: 10px !important;
}

.prof-chat p {
  text-align: right;
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}

.chat-box-main_2 {
  background: white;
  padding: 24px;
  margin-top: 40px;
  border-radius: 30px;
  margin-bottom: 20px;
}

form.send_massage {
  padding: 8px 8px 8px 16px;
  height: 50px;
  box-shadow: 0px 0px 4px 0px #00000026;
  background: white;
  border-radius: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

span.ant-input-affix-wrapper.ant-input-password.ant-input-affix-wrapper-status-success.ant-input-affix-wrapper-has-feedback span.ant-form-item-feedback-icon.ant-form-item-feedback-icon-success {
  position: absolute;
  right: 45px;
  top: 15px;
}

.ant-form-item.username .ant-col.ant-form-item-label {
  font-weight: 600;
}

span.ant-input-affix-wrapper.ant-input-password.ant-input-affix-wrapper-status-error.ant-input-affix-wrapper-has-feedback span.ant-form-item-feedback-icon.ant-form-item-feedback-icon-error {
  position: absolute;
  right: 45px;
  top: 15px;
}

span.ant-input-affix-wrapper.ant-input-password.ant-input-affix-wrapper-status-error.ant-input-affix-wrapper-has-feedback span.anticon.anticon-eye.ant-input-password-icon {
  margin: 0px !important;
}

span.ant-input-affix-wrapper.ant-input-password.ant-input-affix-wrapper-status-success.ant-input-affix-wrapper-has-feedback span.anticon.anticon-eye.ant-input-password-icon {
  margin: 0px !important;
}

.events-name-tt ul li {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

form.send_massage input {
  border: none !important;
  font-size: 14px;
  line-height: 15px;
  font-weight: 500;
  color: #000000;
  width: 100%;
  height: 100%;
}

form.send_massage .message-sending-56gga {
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ebb42c;
  display: flex;
  justify-content: center;
  align-items: center;
}

button.ant-btn.ant-btn-default.message-sending-56ggamain-5 img {
  width: 16px;
  height: 16px;
}

button.ant-btn.ant-btn-default.message-sending-56ggamain-5 {
  border: none;
  box-shadow: none;
  padding: 5px;
}

form.send_massage input:focus-visible {
  outline: none;
}

.main-profile- divv .nav {
  padding: 32px;
  background: white;
  height: 100%;
  max-height: 818px;
  max-width: 308px;
  width: 100%;
}

.main-profile-divv .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  border-radius: 999px;
  background: #021c3f;
  color: white !important;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}

.main-profile-divv .nav-pills .nav-link {
  height: 48px;
  margin-bottom: 16px;
  text-align: left;
  padding: 4px 18px;
  border-radius: 999px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: black;
}

.main-profile-divv .nav-pills .nav-link img {
  background: #e8f1ff;
  padding: 6px 7px;
  border-radius: 50%;
  margin-right: 14px;
}

.main-profile-divv .tab-content>.active {
  display: block !important;
  opacity: 1;
}

.accout-info-orm {
  width: 100%;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 4px 15px 0px #0000001f;
  padding: 30px;
  margin-bottom: 20px;
}

.main-profile-divv .tab-content {
  width: 100%;
}

.list-main-innfor {
  display: flex;
  align-items: center;
  gap: 100px;
  padding-bottom: 15px;
}

.main-profilr-fff {
  position: relative;
  width: 100%;
}

.account-prof-mg {
  flex: 0 0 20%;
  text-align: center;
}

.edit-iconee {
  width: 40px;
  height: 40px;
  bottom: -6px;
  right: 61px;
  position: absolute;
  background: white;
  border-radius: 50%;
  box-shadow: 0px 0px 8.54px 0px #00000026;
  display: flex;
  justify-content: center;
  align-items: center;
}

.account-form-discr {
  width: 100%;
  overflow: hidden;
}

.main-profilr-fff .ant-image {
  max-width: 100px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 50%;
}

.main-profilr-fff .ant-image-mask {
  border-radius: 50%;
}

.jwellers--txt-mmain-img .ant-image-mask {
  border-radius: 50%;
}

.noevent-text .ant-form-item.filtter-select {
  margin: 0px;
}

.main-profilr-fff .ant-image img.ant-image-img {
  border-radius: 50%;
  overflow: hidden;
  width: 100px;
  height: 100px;
}

.account-prof-mg h3 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 5px;
  padding-top: 20px;
}

.account-prof-mg h4 {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  margin: 0;
  color: #121212;
  opacity: 60%;
}

.list-main-innfor h3 {
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  opacity: 60%;
  margin: 0;
  /* width: 172px;
     */
  flex: 0 0 28%;
}

.list-main-innfor h5 {
  font-size: 18px;
  line-height: 29px;
  font-weight: 600;
  margin: 0;
  flex: 0 0 72%;
}

.list-main-innfor:last-child {
  padding: 0px;
}

.accout-main-44 {
  display: flex;
  gap: 40px;
}

.edit-profille {
  text-align: center;
  padding-top: 60px;
}

.edit-profille button,
.account-main button.ant-btn.ant-btn-default.security-btn {
  max-width: 200px;
  width: 100%;
  height: 50px;
  border: none;
  background: #021c3f;
  border-radius: 10px;
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
  color: #f2c230;
  transition: .20s ease-in-out;
}

.my-pro-page h1 {
  padding-bottom: 30px;
  padding-top: 40px;
  margin: 0;
  font-size: 30px;
  line-height: 32px;
  font-weight: 600;
}

.edit-iconee button {
  background: none;
  border: none;
}

form.country_code {
  display: flex;
}

form.main-edit-profille {
  display: grid;
}

.profile_main_edit {
  width: 100%;
}

input.profile-input {
  margin-bottom: 30px;
  padding: 18px 27px;
  border-radius: 12px;
  border: 1px solid #d9d9d9;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  color: #a7a7a7;
}

input.profile-input:focus-visible {
  outline: none;
}

form.country_code select {
  border: none;
  border-right: 0.5px solid #a2a2b5;
}

form.country_code input {
  border: none;
  width: 100%;
}

form.country_code {
  display: flex;
  padding: 18px 27px;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  gap: 8px;
  color: #a7a7a7;
  margin-bottom: 30px;
}

form.country_code input:focus-visible {
  outline: none;
}

form.country_code select:focus-visible {
  outline: none;
}

form.date-picker input {
  width: 100%;
  padding: 18px 27px;
  border-radius: 12px;
  border: 1px solid #d9d9d9;
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  color: #a7a7a7;
}

form.date-picker {
  position: relative;
}

.date-icon {
  position: absolute;
  top: 15px;
  right: 30px;
}

form.gender-picker {
  display: grid;
  gap: 30px;
}

form.gender-picker select {
  padding: 18px 27px;
  border-radius: 12px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  color: #a7a7a7;
  border: 1px solid #d9d9d9;
}

form.gender-picker select:focus-visible {
  outline: none;
}

.main-profile-divv .for-hhh1 {
  height: 100%;
  min-height: 1100px !important;
}

input.profile-input::placeholder,
form.country_code input::placeholder,
form.date-picker input::placeholder {
  color: #a7a7a7;
}

.faveratemain {
  max-width: 100% !important;
  width: 100%;
  border-radius: 20px;
}

.favourites-main-dd .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  width: 100%;
  min-width: 634px !important;
  border-radius: 20px;
  text-align: center;
  height: 74px;
  font-size: 30px;
  line-height: 35px;
  font-weight: 500;
  color: #f2c230 !important;
}

.favourites-main-dd .nav-pills .nav-link {
  width: 633px;
  text-align: center;
  color: #7a797a;
  font-size: 30px;
  line-height: 18px;
  font-weight: 500;
  height: 74px;
  background: #eef5ff;
  margin: 0px;
  border-radius: 20px;
}

.faverate-servicess {
  padding: 20px 20px;
  background: white;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.maain-noevent-imaagee img.ant-image-img {
  width: 500px;
}

.maain-noevent-imaagee {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-spin.ant-spin-lg.ant-spin-spinning.ant-spin-show-text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 70px 0px;
}

.ant-spin-lg .ant-spin-dot {
  font-size:
    50px !important;
}

.ant-spin-lg .ant-spin-dot i {
  width: 20px !important;
  height: 20px !important;
}

.favourites-main-dd {
  padding: 40px 35px;
}

.hrart-inage span.anticon.anticon-heart {
  font-size: 30px;
  color: white !important;
}


.mian-fvrt-services {
  box-shadow: 0px 0px 15.14px 0px #0000001a;
  border-radius: 15px;
  padding: 15px;
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  position: relative;
}

.mian-fvrt-ser-1 img {
  background: linear-gradient(white, white) padding-box, #CE9944 border-box !important;
  border: 2px solid transparent;
  padding: 3px;
  border-radius: 20px;
  max-width: 100px;
  height: 100px;
  width: 100%;
}

.mian-fvrt-ser-1 {
  /* width: 165px !important;
     */
  /* height: 165px !important;
     */
  display: contents;
  width: 100%;
}

.mian-fvrt-ser-2 h3 {
  font-size: 22px;
  line-height: 28px;
  font-weight: 500;
  margin: 0;
}

.mian-fvrt-ser-2 p {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #515b7c;
  padding-bottom: 10px;
  margin: 0;
  width: 90%;
  word-break: break-all;
}

.mian-fvrt-ser-2 h5 {
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
  margin: 0;
  padding-bottom: 5px;
  display: flex;
  gap: 11px;
}

.mian-fvrt-ser-2 h5 span {
  font-size: 18px;
  line-height: 23px;
  color: #a5a5a5;
  text-decoration: line-through;
  font-weight: 400;
}

.rating-fvrt {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: #062550;
  display: flex;
  align-items: center;
}

.rating-fvrt img {
  padding-right: 5px;
  width: 30px;
}

.products-like button {
  border: none;
  background: none;
  width: 29px;
  height: 25px;
}

.products-like {
  position: absolute;
  top: 20px;
  width: 50px;
  height: 50px;
  background: #f7f7f7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 20px;
}

.modal-content.for-forget-passss-main {
  width: 749px;
  height: 594px;
  border-radius: 41px !important;
  padding: 0px 52px;
}

.modal-body.for-forget-passss h4 {
  font-size: 41px;
  line-height: 33px;
  font-weight: 600;
  color: #021c3f;
  margin: 0;
  margin-top: 60px;
  text-align: center;
  padding-bottom: 50px;
}

.change-password input {
  width: 649px;
  height: 72px;
  margin-bottom: 30px;
  border-radius: 12px !important;
  font-size: 20px !important;
  line-height: 24px;
  font-weight: 500 !important;
  color: #a7a7a7 !important;
}

.update-password-btn {
  text-align: center;
  padding-top: 20px;
}

.update-password-btn button {
  width: 576px;
  height: 74px;
  border: none;
  background: #021C3F;
  border-radius: 25px;
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
  color: #F2C230;
}

.header-model-maiai {
  height: 250px;
  background: #021C3F;
  position: relative;
}


.modal-content.for-rate-store {
  overflow: hidden;
  max-width: 666px;
  height: 838px;
  border-radius: 27px !important;
  padding: 0px;
  background: white;
  box-shadow: 0px 5.53px 41.46px 0px #00000033;
  margin: 0 auto;
  width: 100%;
}

.submit-button-mainnn button {
  max-width: 300px;
  width: 100%;
  height: 45px !important;
  border: none;
  background: #021c3f !important;
  border-radius: 10px !important;
  font-size: 18px !important;
  line-height: 32px !important;
  font-weight: 500 !important;
  border: 1px solid #021c3f;
  color: #f2c230 !important;
}

button.ant-btn.ant-btn-default.cancle-rate-btn {
  width: fit-content !important;
  border: 2px solid #010f23;
  font-size: 18px;
  font-weight: 600;
  color: #010f23;
  height: 46px;
}

.happy-face-img {
  position: absolute;
  bottom: -81px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.rateing-ss {
  position: absolute;
  text-align: center;
  bottom: -175px;
  width: 100%;
  margin: 0 auto;
  display: grid;
  justify-content: center;
}

.rateing-ss h3 {
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
  color: black;
  margin: 0;
}

.rateing-ss p {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  width: 337px;
  color: black;
  margin: 0;
}

.srtars-mmain-rate {
  padding-top: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.srtars-mmain-rate img {
  width: 30px;
}

.coment-main-2 {
  padding: 0px 34px;
  padding-bottom: 34px;
  padding-top: 27px;
}

.coment-main-2 h4 {
  font-size: 19px;
  line-height: 24px;
  font-weight: 500;
  margin: 0;
  padding-bottom: 10px;
}

.coment-main-2 input {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  border: 1px solid #EAEAEA;
  box-shadow: 0px 0px 15px 0px #00000017;
  padding: 26px;
  min-height: 138px;
  line-height: 18px;
}

.coment-main-2 input:focus-visible {
  outline: none;
}

.yes-no-butt {
  padding: 0px 33px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 13px;
}

.yes-no-butt button {
  width: 50%;
  height: 50px;
  border: none;
  border-radius: 10px;
}

.modal-body.rate-store-body {
  background: white;
}

button.btn-no {
  background: #EEF5FF;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
}

button.btn-yes {
  font-size: 18px;
  line-height: 21px;
  font-weight: 600;
  color: #F2C230;
  background: #021C3F;
}

.modal-content.for-invt-frnd {
  width: 666px;
  height: 838px;
  border-radius: 30px !important;
  padding: 33px;
  margin-bottom: 20px;
}

.edit-profille button:hover,
.account-main button.ant-btn.ant-btn-default.security-btn:hover,
.submit-button-maain button.ant-btn.ant-btn-submit:hover,
.ant-modal.tab_modal .ant-modal-footer button.ant-btn.ant-btn-primary.btn-primary-ft:hover,
.gmail-box button:hover,
.gmail-box-1 button.ant-btn.ant-btn-default:hover,
.main-quotation-detailss .masspge-main-dic:hover,
button.ant-btn.ant-btn-default.new-event-button:hover,
button.ant-btn.ant-btn-primary.submit-button:hover,
.main-share-bttnn button.ant-btn.ant-btn-default.ant-btn-icon-only:hover,
button.ant-btn.ant-btn-default.dleltebutton-image-22:hover,
.add-sign-new:hover,
.deleteall-main-button:hover {
  border: 1px solid #F2C230 !important;
  background: #F2C230 !important;
  color: #062550 !important;
}

.invite-frnd-img {
  text-align: center;
  padding-bottom: 45px;
}

.invite-txxt {
  text-align: center;
  padding-bottom: 10px;
}

.invite-txxt h3 {
  font-size: 27px;
  line-height: 18px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 10px;
}

.invite-txxt p {
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  color: #5B5B5B;
  margin: 0;
  padding-bottom: 5px;
  max-width: 442px;
  margin: 7px auto;
  width: 100%;
}

.frnds-mail input {
  width: 100%;
  border: 0.67px solid #D9D9D9;
  border-radius: 8px;
  padding: 15px 25px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  color: #A7A7A7;

  height: 47px;
}

.modal-body.invite-frnds-maain .ant-form-item {
  padding-bottom: 20px;
}

form.country_frnd-code {
  display: flex;

}

form.country_frnd-code input {
  border: none;
  border-radius: unset;
  padding: 10px;
  margin: 0;
}

form.country_frnd-code {
  display: flex;
  border: 0.67px solid #D9D9D9;
  border-radius: 8px;
  margin-bottom: 38px;
  overflow: hidden;
}

form.country_frnd-code input:focus-visible {
  outline: none;
}

form.country_frnd-code select:focus-visible {
  outline: none;
}

form.country_frnd-code select {
  border-left: 1px;
  border: none;
  padding-left: 24px;
  border-right: 0.33px solid #A2A2B5;
  /* margin-right: 10px; */
  /* padding-right: 10px; */
  /* width: 90px; */
}

.reffer-code {
  display: grid;
  align-items: center;
  justify-content: center;
  border: 2.25px dashed #062550;
  text-align: center;
  width: 188px;
  margin: 0 auto;
  /* padding: 17px 36px; */
  height: 85px;
  border-radius: 13px;
}

.ivnvt-frnd-bttn button {
  border: none;
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
  padding: 13px 145px;
  border-radius: 16px;
  background: #021C3F;
  color: #F2C230;
}

.ivnvt-frnd-bttn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 49px;
  width: 100%;
}

.reffer-code h4 {
  font-size: 20px;
  line-height: 18px;
  font-weight: 500;
  color: #073C51;
  margin: 0;
}

.reffer-code p {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: #5B5B5B;
  margin: 0;
}

.about-us-maain {
  padding-top: 40px;
  text-align: justify;
}

.about-us-maain h3 {
  font-size: 30px;
  line-height: 36px;
  color: #121212;
  font-weight: 600;
  margin: 0;
  margin-bottom: 30px;
}

.about-us-discripton {
  padding: 40px 40px 40px 40px;
  box-shadow: 0px 4px 15px 0px #0000001f;
  border-radius: 20px 20px 0px 0px;
  background: white;
  max-height: 841px;
  overflow-y: scroll;
  scrollbar-width: none;
  height: 100%;
}

.prvecy-main-discription {
  padding-top: 10px;
  background: white;
  box-shadow: 0px 4px 15px 0px #0000001f;
  border-radius: 20px 20px 0px 0px;
  max-height: 564px;
  overflow-y: scroll;
  scrollbar-width: none;
  height: 100%;
}

.about-us-discripton h2 {
  font-size: 18px;
}

.about-us-discripton p {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #515B7C;
  margin: 0;
  padding-bottom: 10px;
}

.privecy-main-discription-1 {
  padding-left: 35px;
  padding-top: 40px;
  border-radius: 20px 20px 0px 0px !important;
}

.privecy-main-discription-1 h3 {
  color: #121212;
  line-height: 24px;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 30px;
}

.prvecy-main-discription .accordion-item {
  border: none;
  border-bottom: .5px solid #e3e3e3 !important;
}

.prvecy-main-discription .accordion-body {
  padding: 20px 20px;
  background: #F6F7FB;
  box-shadow: 0px 10px 14px 0px #0000000D;
}

.country-wrap.country-wrapmain-sam .ant-select-selector {
  height: 43px !important;
}

.prvecy-main-discription .accordion-body p {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #515B7C;
  margin: 0;
  padding-bottom: 10px;
}

.prvecy-main-discription button.accordion-button {
  font-size: 28px;
  line-height: 32px;
  font-weight: 500;
  padding-left: 40px;
  padding-bottom: 34px;
  color: black;
}

.prvecy-main-discription .accordion-button::after {
  background-size: 20px;
  width: 20px !important;
  height: 20px !important;
}

.bgtt-listt .accordion-item {
  background: none;
  border: none;
}





section.invt_bg.for-filter {
  margin: 0px !important;
}


.main-quotation-detailss .accordion-item {
  border-radius: 24px !important;
  background: #F9F9FC !important;
  border: none !important;
  box-shadow: 0px 0px 20px 0px #0000001A;
  padding: 20px;
  margin: 0;
  margin-top: 25px;
}

.main-quotation-detailss button.accordion-button {
  padding: 0;
}

.main-quotation-detailss button.accordion-button {
  display: block;
}

.main-quotation-detailss .accordion-header {
  margin-bottom: 0;

  overflow: hidden;
}

.main-quotation-detailss .accordion-body {
  padding: 0 !important;
  padding-top: 30px !important;
}

.main-quotation-detailss .pdf-main {
  margin: 10px 0px;
  max-width: 100px;
  width: 100%;
  height: 100px;
  overflow: hidden;
}

.main-quotation-detailss .add-to-event-my {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #F3F3F3;
  padding: 10px 10px;
  border-radius: 10px;
  gap: 25px;
}

.main-quotation-detailss .masspge-main-dic {
  width: 60px;
  height: 60px;
  background: #021C3F;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .20s ease-in-out;
}

.main-quotation-detailss .add-to-event-my button {
  width: 100%;
  border: 1px solid #E6E6E6;
  background: #F3F3F3;
  height: 60px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.main-quotation-detailss .add-to-event-my button span {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  color: #121212;
}

.main-quotation-detailss .for-clrr-i-b {
  background: white !important;
}

.main-quotation-detailss .for-clrr-i-b-ff {
  border: none !important;
}

.ant-tabs.ant-tabs-top.category-tab .ant-tabs-nav-list {
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
}

.ant-tabs.ant-tabs-top.category-tab .ant-tabs-tab.ant-tabs-tab-active {
  border: none;
  background: #152d4e;
  border-radius: 12px !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 35px;
  color: white !important;
  width: 200px;
  /* height: 50px; */
  text-align: center;
  align-items: center;
  justify-content: center;
}

.ant-tabs.ant-tabs-top.category-tab .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  display: none;
}

.ant-tabs.ant-tabs-top.category-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #d8d8d8;
}

.ant-tabs.ant-tabs-top.category-tab .ant-tabs-nav {
  margin-bottom: 50px !important;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.model-form-main-1 form.ant-form.ant-form-vertical.row-col {
  padding: 0px 68px;
}

.login-name span {
  font-size: 30px;
  line-height: 36px;
  font-weight: 600;
  color: #062550;
}

.login-name {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-bottom: 30px;
}


.model-form-main-1 input#email,
.ant-select-selector,
.model-form-main-1 span.ant-input-affix-wrapper.ant-input-password,
.ant-form-item-control-input-content input,
.ant-form-item.username.ant-form-item-has-success .ant-picker.ant-picker-status-success,
.ant-form-item.username.ant-form-item-has-success .ant-select-selector,
.react-tel-input .form-control,
.ant-picker.date-inn,
.ant-input-number.ant-input-number-in-form-item.number-inn,
.ant-picker {
  border: 1px solid #D9D9D9;
  height: 48px !important;
  border-radius: 7px !important;
  /* background: #fff !important; */
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  color: #000;
  padding: 0px 10px;
  letter-spacing: 2px;
  width: 100% !important;
  /* border: none !important; */
  box-shadow: none !important;
}

.ant-select-selector {
  border: 1px solid #D9D9D9 !important;
  height: 48px !important;
  border-radius: 7px !important;
  background: #fff !important;
  font-size: 14px !important;
  line-height: 19px !important;
  font-weight: 400 !important;
  color: #000 !important;
  padding: 0px 10px !important;
  letter-spacing: 2px;
  width: 100% !important;
  box-shadow: none !important;
}

.noproviders img {
  width: 500px;
}

.noproviders {
  text-align: center;
}

input#country_id,
input#city_id,
input#rc_select_0,
input#rc_select_1,
input#dob {
  background: transparent !important;
}

.ant-form-item-explain-error {
  color: #ff4d4f;
  text-align: left;
}

/* .ant-select.ant-select-in-form-item.select-inn .ant-select-selector {
  border: 1px solid #D9D9D9;
  height: 48px !important;
  border-radius: 7px;
  background: #e8f0fe !important;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: #A7A7A7;
  padding: 0px 20px;
  letter-spacing: 2px;
  width: 100% !important;
  border: none !important;
  box-shadow: none !important;
} */
.ant-select-item.ant-select-item-option {
  text-align: left;
}

h4.for-view-all {
  height: 100% !important;
}

input:focus {
  background-color: white !important;
}

label.btn.searchBtn.d-flex.align-items-cneter.justify-content-center.m-0.rounded-1.border-0.shadow-none.position-absolute.start-0.top-0.bottom-0.me-1.my-auto {
  z-index: 10 !important;
}

input[type="text"],
input[type="email"],
input[type="password"] {
  background-color: white !important;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus {
  background-color: white !important;
}

.vensu-teext.vensu-teext-667 ul {
  display: flex;
  align-items: center;
  gap: 20px;
  list-style: none;
  flex-wrap: wrap;
}

.vensu-teext.vensu-teext-667 {
  margin-top: 23px;
}

.vensu-teext.vensu-teext-667 ul li img {
  width: 23px;
  height: 23px;
  margin-right: 10px;
  flex: 0 0 23px;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0;
  line-height: 54px !important;
  transition: all 0.3s, visibility 0s;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.react-tel-input .selected-flag {
  background-color: transparent !important;
  width: 43px;
}

.react-tel-input input.form-control {
  padding-left: 50px !important;
}

input.form-control {
  padding-left: 10px !important;
  color: black !important;
  font-weight: 500 !important;
}

.headerResultOuter {
  z-index: 99;
}

.headerSearch {
  max-width: 350px;
  width: 100%;
  min-width: 800px;
}

a.headerResultMenu:last-child {
  border-bottom: 0px solid !important;
}

.flag-dropdown {
  background-color: transparent !important;
  border: none !important;
  border-right: 1px solid #d0d5dd !important;
}

.model-form-main-1 .ant-col.ant-form-item-control {
  margin-bottom: 20px;
}

.model-form-main-1 a#remember a {
  color: #062550;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
}

.model-form-main-1 .ant-form-item.aligin-center {
  margin: 0;
}

.model-form-main-1 .ant-col.ant-form-item-control {
  text-align: end;
}

.model-form-main-1 button.ant-btn.ant-btn-primary.float-right {
  width: 100%;
  background: #021C3F;
  height: 50px;
  border-radius: 15px;
  color: #F2C230;
  font-size: 18px;
  font-weight: 600;
  line-height: 19px;
  outline: none;
  border: 1px solid #021c3f;
  box-shadow: none;
}

.main-dltall-not-all button {
  padding: 2px 10px;
}

span.ant-input-affix-wrapper.ant-input-password.ant-input-affix-wrapper-status-error.ant-input-affix-wrapper-has-feedback span.anticon.anticon-eye-invisible.ant-input-password-icon {
  margin: 0px !important;
}

.ant-modal-close {
  top: 25px !important;
  right: 25px !important;
  background-size: 43px !important;
  border: 2px solid #062550 !important;
  border-radius: 50% !important;
}

span.anticon.anticon-close.ant-modal-close-icon {
  font-size: 16px !important;
  color: #062550 !important;
}

span.ant-modal-close-x {
  width: 37px !important;
  height: 37px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ant-modal.tab_modal.second-modal .ant-modal-body {
  padding: 0px;
}

.ant-modal.tab_modal.sec ond-modal .ant-modal-content {
  border-radius: 50px;
  overflow: hidden;
  padding: 0px;
}

form#forget-pasword {
  padding: 0px 68px;
}

h4.modal_title_cls {
  font-family: Montserrat;
  font-size: 30px;
  font-weight: 600;
  line-height: 36.57px;
  color: #062550;
  display: flex;
  align-items: center;
  gap: 20px;
  padding-bottom: 30px;
  margin: 0;
  justify-content: flex-start;
}

.forgot-pass {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}

.main-otp-model .ant-modal-body {
  padding: 0px;
  border-radius: 50px;
}

.main-otp-model .ant-modal-content {
  border-radius: 50px;
  overflow: hidden;
  padding: 0px;
}

.model-form-main-1 form#verify-otp {
  padding: 0px 68px;
}

/* .main-otp-model  .ant-form-item-control-input-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
} */
form#verify-otp div#otp {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 40px;
}

.ant-modal.tab_modal.second-modal.main-three form#reset-password {
  padding: 0px 56px;
}

form#verify-otp div#otp input {
  width: 54px;
  height: 54px;
  border-bottom: 3px solid #021C3F !important;
  font-size: 20px !important;
  line-height: 26px !important;
  font-weight: 500;
  color: #021C3F;
  text-align: center;
  padding: 0;
}

form#verify-otp div#otp_help div {
  text-align: left;
  padding-top: 10px;
  font-size: 15px;
  font-weight: 600;
}

.ant-modal.main-model_imgg1.signin-modeel form.ant-form.ant-form-vertical.row-col {
  padding-top: 67px;
}

.ant-modal.main-model_imgg1.signin-modeel input#number {
  border: 1px solid #D9D9D9;
  height: 55px;
  border-radius: 7px;
  background: white;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: #A7A7A7;
  padding: 0px 20px;
  letter-spacing: 2px;
}

.ant-modal.main-model_imgg1.signin-modeel .ant-form-item {
  margin-bottom: 10px;
}

.already-have {
  text-align: center;
  font-size: 18px;
  line-height: 21px;
  font-weight: 600;
  color: #757575;
}

.already-have a {
  color: #021C3F !important;
  font-weight: 700;
}

button.google-button-img {
  padding-bottom: 20px;
}

.ant-tabs.ant-tabs-top.offering-tabbs .accordion-button::after {
  position: absolute;
  right: 20px;
  bottom: 34px;
  background-color: #EEF5FF;
  border-radius: 999px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position-x: center;
  background-position-y: center;
  background-size: 17px;
}

.ant-tabs.ant-tabs-left.message-min button#rc-tabs-5-more {
  display: none;
}

.account-main {
  background: white;
  padding: 55px;
  display: grid;
  place-content: center;
  width: 100%;
  border-radius: 20px;
}

h1.ant-typography.tital-text-sign {
  color: #021C3F;
  font-size: 45px;
  padding-bottom: 32px;
}

.tital-text-sign {
  padding-top: 40px;
  padding-bottom: 30px;
  font-size: 30px;
  line-height: 36px;
  color: #121212;
  font-weight: 600;
  margin: 0;
}

.account-main span.ant-input-affix-wrapper.ant-input-password.form-of-type {
  border: 1px solid #D9D9D9;
  height: 50px;
  border-radius: 10px;
  padding: 0px 24px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #A7A7A7;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
}

.account-outers {
  background: #F6F7FB;
}

.account-main .ant-form-item-control-input-content {
  display: flex;
  justify-content: center;
  align-items: center;
}



.modal-dialog {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content.for-invt-frnd {
  width: 666px;
  height: 900px;
  border-radius: 27px !important;
  padding: 33px;
  background: white;
  box-shadow: 0px 5.53px 41.46px 0px #00000033;
}

.ant-tabs.ant-tabs-left.message-min .ant-tabs-nav-wrap {
  padding: 32px;
  background: white;
  width: 308px;
}

.ant-tabs.ant-tabs-left.message-min .ant-tabs-tab-btn span img {
  background: #E8F1FF;
  border-radius: 50%;
  padding: 6px;
  margin-right: 15px;
}

.ant-tabs.ant-tabs-left.message-min .ant-tabs-tab-btn {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}

.ant-tabs.ant-tabs-left.message-min .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1890ff;
  text-shadow: 0 0 0.25px currentcolor;
  color: white;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.ant-tabs.ant-tabs-left.message-min .ant-tabs-tab:hover {
  color: black;
}

.ant-tabs.ant-tabs-top.message-maaxx .ant-tabs-nav {
  margin: 0px !important;
  padding: 0px;
}

.ant-tabs.ant-tabs-top.message-maaxx .ant-tabs-tab {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}

.ant-tabs.ant-tabs-top.message-maaxx .ant-tabs-tab-btn {
  font-size: 22px;
  line-height: 18px;
  font-weight: 400;
  color: #7A797A;
}

.ant-tabs.ant-tabs-top.message-maaxx .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #F2C230;
  font-size: 22px;
  font-weight: 400;
  line-height: 18px;
}

.ant-tabs.ant-tabs-top.message-maaxx .ant-tabs-nav-list {
  background: #EEF5FF;
  border-radius: 20px;
  width: 100%;
}

h2.freq-ask-neww.accordion-header button.accordion-button {
  padding: 15px 20px;
  color: #021C3F;
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 500 !important;
}

/* .birthday-new-main img:hover,
.birthday-new:hover {
  transform: scale(1.09);
} */

section.galllery -sec .owl-carousel .owl-stage-outer {
  overflow: visible;
}

.birthday-new-main img,
.birthday-new {
  transition: .30s ease-in-out;
}

/* 
.bookk-btm {
  color: #FFF;
  transition: all 0.5s;
  position: relative;
}
.bookk-btm::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(255,255,255,0.1);
  transition: all 0.3s;
}
.bookk-btm:hover::before {
  opacity: 0 ;
  transform: scale(0.5,0.5);
}
.bookk-btm::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border: 1px solid rgba(255,255,255,0.5);
  transform: scale(1.2,1.2);
}
.bookk-btm:hover::after {
  opacity: 1;
  transform: scale(1,1);
} */


.bookk-btm {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.bookk-btm a {
  position: relative;
  transition: all .45s ease-Out;
}

#translate {
  transform: rotate(50deg);
  width: 100%;
  height: 250%;
  left: -280px;
  top: -30px;
  background: #77869d;
  position: absolute;
  transition: all .3s ease-Out;
}

.bookk-btm:hover #translate {
  left: 0;
}

.bookk-btm:hover a {
  color: #2D3142;
}

section.tabbs-section .owl-carousel .owl-stage-outer {
  overflow: visible;
}


.reset-pass-model input#newPassword,
.reset-pass-model input#confirm_new_password,
input#password,
input#confirm_new_password {
  width: 100%;
  height: 100% !important;
  padding: 0px 20px;
}

.main-quotation-detailss {
  background: white;
  border-radius: 50px;
  padding: 30px;
}

.reset-pass-model {
  padding: 0px 35px;
}

.for-like-share {
  position: absolute;
  right: 110px;
  top: 20px;
  width: 160px;
  height: 60px;
  background: #ffffff1f;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
}

span.active_notification.active_notification-999.active_notification-998 {
  top: 3px;
  right: -2px;
}

.main-quotation-detailss input:focus-visible {
  border: none !important;
  width: 100%;
  height: 100%;
  outline: none;
}



.for-q_pagess p {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  margin: 0;
  padding-bottom: 10px;
}


.for-like-share button {
  border: none;
  background: none;
  padding: 0px;
  margin: 0px;
  height: 100%;
}

.userImg .ant-image img {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  border: 1px solid black;
}

.profileDropdownMain button {
  border: none;
  background: transparent;
  color: white;
  font-weight: 600;
  font-size: 20px;
  padding: 0;
  width: 100%;
  height: 100%;
}

.userName {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 9px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  letter-spacing: 1px;
}

.dropdown-menu.show {
  background: #062550;
}

.ant-modal.noevent-model .ant-modal-header {
  display: none;
}

.ant-modal.noevent-model .ant-modal-body {
  padding: 0;
}

.ant-modal.noevent-model .ant-modal-content {
  box-shadow: 0px 8.3px 62.24px 0px #00000033;
  border-radius: 41px;
  height: 100%;
}

.ant-modal.noevent-model .event-backgg {
  position: relative;
}

.ant-modal.noevent-model .event-happy-emoji {
  position: absolute;
  top: 53px;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noevent-text h2 {
  font-family: Roboto;
  font-size: 41.5px;
  font-weight: 500;
  line-height: 33.2px;
  color: #021C3F;
  margin: 0;
  padding-bottom: 40px;
}

.noevent-text p {
  font-family: Roboto;
  font-size: 29.05px;
  font-weight: 400;
  line-height: 34.04px;
  text-align: center;
  margin: 0;
  padding-bottom: 50px;
}

.new-event-buttonmain-button9966 {
  width: 100%;
  padding-bottom: 15px;
}

.noevent-text button {
  max-width: 250px;
  height: 50px;
  background: #021C3F;
  border-radius: 7px;
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  color: #F2C230;
  width: 100%;
}

.noevent-text {
  text-align: center;
}

ul.navbar-nav.ms-auto.mb-2.mb-lg-0 {
  align-items: center;
}


.side-nav-main {
  position: relative;
  overflow: hidden;
}

.side-nav {
  max-width: 100%;
  padding: 15px;
  border-radius: 0px !important;
}

.fillter-sidebar {
  padding: 10px 10px 0px;
  max-width: 250px;
  width: 100%;
  height: 45px;
}

header {
  padding: 15px 15px !important;
}

.sidehide a.sidebar-btn {
  width: 100%;
  font-family: Inter;
  max-width: 50px;
  height: 45px;
  background-color: #65c953;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #fff !important;
  font-size: 25px;
  transition: all 0.5s;
  -webkit-transition: all 0.25s;
}


.nav-items-name {
  display: block;
  align-items: baseline;
  gap: 15px;
  min-height: 100% !important;
  margin-top: 16px;
  height: 100%;
}

.side-nav {
  padding: 32px;
  box-shadow: 0px 0px 4px 0px #00000026;
  height: 100%;
  min-height: 500px;
  background-color: white !important;
}

.fillter-sidebar {
  display: none;
}

.side-nav ul li {
  width: 100%;
  max-width: 100%;
  margin-bottom: 18px;
  padding: 8px 10px 8px 10px;
  display: flex;
  align-items: center;
  height: 48px;
}

.ant-tabs.ant-tabs-top.message-maaxx {
  margin-top: 20px;
  background: white;
  border-radius: 20px;
}

.ant-tabs.ant-tabs-top.message-maaxx button#rc-tabs-0-more {
  display: none;
}

.side-nav li.nav-items.mobile-side.active {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #4b4b4b;
}

.side-nav li.nav-items.active a {
  color: #fff;
}

.side-nav li.nav-items.active {
  background-color: #021C3F;
  border-radius: 999px;
  /* border: 1px solid #4b4b4b; */
}

.side-nav li.nav-items.mobile-side.active a {
  color: #000000;
}

.accounts-detail-list {
  padding-right: 10px;
}

.side-nav li.nav-items a img {
  border-radius: 50%;
  background: #E8F1FF;
  padding: 7px;
  width: 32px;
  height: 32px;
}

li.nav-items a {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: #121212;
  display: flex;
  align-items: center;
}

li.nav-items a img {
  margin-right: 8px;
  width: 20px;
  height: 20px;
}


.ant-modal-content {
  padding: 25px 30px;
}

.event-backgg-header h2 {
  font-size: 22px;
  line-height: 36px;
  font-weight: 600;
  max-width: 360px;
  width: 100%;
  text-align: center;
  margin: 0;
  padding-bottom: 40px;
  color: #021c3f;
}

.event-backgg-header {
  font-size: 30px;
  line-height: 36px;
  font-weight: 600;
  width: 518px;
  color: #121212;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

form.ant-form.ant-form-horizontal.noevent-maain-model .ant-picker,
form.ant-form.ant-form-horizontal.noevent-maain-model .ant-select-selector,
form.ant-form.ant-form-horizontal.noevent-maain-model input.ant-input {


  width: 100%;
  background-color: white;
  border: 1px solid #D9D9D9;
  height: 72px;
  border-radius: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  padding: 0px 40px;
}

form.ant-form.ant-form-horizontal.noevent-maain-model span.ant-select-arrow {
  right: 27px;
  font-size: 20px;
  color: black;
}

form.ant-form.ant-form-horizontal.noevent-maain-model span.ant-picker-suffix {
  font-size: 20px;
  color: black;
}


.ant-modal.tab_modal.deleteWarningModal.modal01 .ant-modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.ant-modal.tab_modal .ant-modal-footer button.ant-btn.ant-btn-primary.btn-primary-ft {
  max-width: 110px;
  width: 100%;
  height: 50px;
  border: none;
  background: #021c3f !important;
  border-radius: 10px;
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
  color: #f2c230 !important;
  margin: 0px;
}

.ant-modal.tab_modal .ant-modal-footer button.ant-btn.ant-btn-default.btn-primary-cancel {
  max-width: 100px;
  width: 100%;
  height: 50px;
  background: #fff;
  border-radius: 10px;
  font-size: 18px !important;
  border: 1px solid #ccc !important;
  line-height: 32px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #021c3f;
}

.ant-modal.tab_modal.deleteWarningModal.modal01 .ant-modal-content {
  padding: 20px !important;
  border-radius: 20px !important;
}

.short-by-div .ant-select-selector {
  height: 47px !important;
  width: 159px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
}

.short-by-div .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 8px;
}

.ant-select.button-f irsttt.ant-select-single.ant-select-show-arrow .ant-select-selector {
  background: #062550 !important;
  border: none !important;
  outline: none;
  color: white;
}

.massage-text a {
  color: white !important;
}

.result-msin-divv h4 {
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
  color: #686868;
  margin: 0;
}

.result-msin-divv h4 span {
  color: black;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

/* section.filter-bg .panel-heading button {
  display: none;
} */

.filter-bg .panel-heading button {
  background: none !important;
  border: none;
  box-shadow: none !important;
  margin: 0;
  padding: 0 !important;
  float: left !important;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #0d2444;
  height: 100%;
  padding: 10px 0px !important;
}

.main-1div-all {
  display: flex;
  gap: 20px;
}

.main-2div-all {
  width: 373px !important;

}


.blog-page-main {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 25px;
}

.blog-page-main .ant-card-cover img {
  width: 100%;
  height: 207px;
}

.ant-card.ant-card-bordered.ant-card-hoverable {
  background: #021c3f;
  color: white;
  border-radius: 20px;
  overflow: hidden;
}

.blog_pge-txt h5 {
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
  color: white;
  margin: 0;
  padding-bottom: 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 63px;
}

.blog_pge-txt p {
  margin: 0;
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 60px;
}

button.ant-btn.ant-btn-primary img {
  width: 22px;
  height: 20px;
}

button.ant-btn.ant-btn-primary {
  /* margin-top: 10px; */
  background: white;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  color: #021c3f;
  gap: 10px;
}

.ant-btn-primary:hover {
  background: #8099bb !important;
  color: black !important;
  border: none;
}

.blog-page-main {
  padding: 45px 0px;
}

.ant-card-hoverable:hover {
  border-color: transparent;
  box-shadow: 0 20px 14px -2px rgba(0, 0, 0, 0.16), 0 15px 25px 0 rgba(0, 0, 0, 0.12), 0 15px 30px 15px rgba(0, 0, 0, 0.09);
}

.main-aayur .ant-card-body {
  padding: 0px;
}

.blog_pge-txt {
  padding: 0px 15px 15px 15px;
}

.main-aayur .main-heading-date h3 {
  padding-left: 15px;
}

.heading_333 p {
  margin: 0;
  font-size: 22px;
  line-height: 38px;
  font-weight: 500;
  color: #021c3f;
  padding-bottom: 12px;
}

.css-13cymwt-control {
  background: white;
  border: none;
  outline: none;
}

.birthday-new {
  width: 100%;
  height: 225px;
}

.birthday-new img {
  height: 100%;
  object-fit: cover;
}

.abouut-us-txxt h4 {
  font-size: 24px;
  font-weight: 500 !important;
  line-height: 38px;
  color: #0d2444;
}

.wedding-main.category-imgs .birthday-new {
  width: 100%;
  height: 145px;
}

.main-3div-all .wedding-main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nosub-ccc img {
  width: 100%;
  max-width: 500px;
  height: 100%;
  max-height: 500px;
}

/* input#email {
  background: transparent; 
  border: 0px;
} */

.gmail-box-1 .ant-form-item {
  margin: 0px !important;
  margin-bottom: 0px !important;
  width: 100%;
}

.gmail-box-1 input {
  padding: 0;
  margin: 0;
}

.gmail-box-1 button.ant-btn.ant-btn-default {
  color: white;
  font-size: 18px;
  line-height: 22px;
  background: #062550;
  border: 1px solid #062550;
}

.gmail-box-1 button.ant-btn.ant-btn-default:hover {
  background-color: #2e5791;
}

.gallery-items .ant-image {
  width: 100%;
  height: 100%;
  display: inline-flex;
  border-radius: 30px;
  overflow: hidden;
}

.ant-tabs.ant-tabs-top.offering-tabbs {
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 15px !important;
  overflow: hidden;
}

.ant-tabs-tabpane {
  padding: 20px 22px;
}

.filtter-select .ant-select.ant-select-in-form-item.form-select.ant-select-single {
  border: 0px solid #D9D9D9;
  height: 55px;
  border-radius: 7px;
  background: white;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  color: #A7A7A7;
  letter-spacing: 2px;
  width: 100% !important;
  display: flex;
  align-items: center;
}

.ant-tabs-top>.ant-tabs-nav::before {
  border-bottom: 0px !important;
}

.filtter-select .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 55px;
  padding: 0 10px;
  border-radius: 7px;
  border: 1px solid #d9d9d9;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-input:hover,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-radio:hover .ant-radio-inner {
  border-color: #d9d9d9 !important;
  border-right-width: 1px;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
  border-right-width: 1px;
  outline: 0;
}

.ant-input:focus,
.ant-input-focused {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
  border-right-width: 1px;
  outline: 0;
}

.ant-form-item.username textarea.ant-input {
  border: 1px solid #D9D9D9;
  height: 100px !important;
  border-radius: 7px;
  background: white;
  font-size: 16px;
  line-height: 25px;
  font-weight: 400;
  color: #A7A7A7;
  letter-spacing: 2px;
  width: 100% !important;
}

.product-img img {
  height: 205px;
  object-fit: fill;
}

.owl-carousel .owl-item {
  min-height: 0px !important;
}


.notification-header .ant-btn-default.ant-dropdown-trigger {
  padding: 0;
  border: 0;
  background: transparent !important;
  position: relative;
  width: 30px;
}

.notification-header .ant-btn-default.ant-dropdown-trigger:after {
  content: '';
  inset: unset !important;
  position: absolute;
  height: 1 2px;
  width: 12px;
  background: #FF3B30;
  border-radius: 50%;
  top: 1px !important;
  right: 2px !important;
  opacity: 1 !important;
  box-shadow: none !important;
  outline: 0 !important;
}

.main-fav-b-1 span.anticon.anticon-heart {
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* input:-internal-autofill-selected {
  background-color: white !important;
} */

button.ant-btn.ant-btn-primary.float-right:hover,
.report-button button:hover {
  border: 1px solid #F2C230;
  background: #F2C230 !important;
  color: #062550 !important;
}

.main-divv-bggg img {
  width: 100%;
  height: 800px;
  object-fit: cover;
  object-position: bottom;
}

.image {
  width: 100%;
  transition: width 0.5s ease-in-out;
}

.image.shrink {
  width: 50%;
  /* Ya jitna chhota karna ho */
}

input:-internal-autofill-selected {
  background-color: white !important;
  background: white !important;
}

.ant-dropdown.ant-dropdown-placement-bottomRight {
  top: 70px !important;
}

.ant-dropdown.ant-dropdown-placement-bottomRight {
  min-width: inherit !important;
}

.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light {
  box-shadow: 0px 0px 25px 0px #0000000F;
  border-radius: 8px;
  margin: 0 !important;
  padding: 7px !important;
  /* max-width: 420px; */
}

.ant-dropdown ul.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light li:last-child {
  border-bottom: 0;
}

.ant-dropdown ul.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light li {
  /* padding: 15px 10px !important; */
  margin: 0;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.88);
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5714285714285714;
  cursor: pointer;
  transition: all 0.2s;
  border-radius: 4px;
}

button.ant-btn.ant-btn-default.ant-dropdown-trigger.ant-dropdown-open.notification-box img {
  filter: invert(1);
}

li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child:hover {
  border-radius: 20px;
}

.viewAll_notification button.ant-btn.ant-btn-default.btnStyle.btn_primary {
  background: #021c3f;
  color: #ce9944;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
}

.notification-head {
  padding: 15px;
  border-bottom: 1px solid #E9E9E9;
}

.notification-head h5 {
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  color: #323637;
  margin-bottom: 0;
}

.story-item video {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.gallery-items02 video {
  height: 200px;
  width: 100%;
  max-width: 100%;
  object-fit: cover;
}

.notification-inner {
  padding: 0px 25px 20px;
}

.viewAll_notification {
  text-align: center;
}

.main-new-search-serviceshh .product-img01 a img {
  width: 90px !important;
  height: 90px !important;
}

.no-active_event .ant-picker-status-error.ant-picker-focused {
  width: 100%;
  height: 48px;
  border-radius: 7px;
  border-color: #d9d9d9;
  box-shadow: unset;
  border-right-width: 1px;
  outline: 0;
  width: 100%;
  overflow: hidden;
  background: #e5edfb;
}

.no-active_event input#dob {
  width: 100%;
  height: 100%;
}

.no-active_event .ant-select-selector {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-modal.noevent-model .ant-modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
}

button.ant-btn.ant-btn-primary.ok-button-class {
  max-width: 170px;
  width: 100%;
  height: 46px;
  border-radius: 10px;
  background: #010f23;
  color: #ffbf00;
  border-color: #010f23;
}

button.ant-btn.ant-btn-primary.ok-button-class:hover,
button.ant-btn.ant-btn-primary.btn-primary-ft:hover {
  border: 1px solid #F2C230;
  color: #010f23 !important;
  background: #F2C230 !important;
  font-weight: 600 !important;
}

.ant-col.submit-button-maain.ant-col-md-24.ant-col-xl-24 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
}

.submit-button-maain button.ant-btn.ant-btn-submit {
  max-width: 197px;
  width: 100%;
  height: 46px;
  border-radius: 10px;
  background: #010f23;
  color: #ffbf00;
  border: 1px solid #010f23;
  font-size: 18px;
}

.container.for-all-short-w {
  width: 100%;
  max-width: 1320px !important;
}

.add-items-1 img {
  width: 24px;
  height: 24px;
}

.main-mmount-44 .add-price {
  color: #021c3f;
  display: flex;
  align-items: center;
  justify-content: end;
}

.add-price h3 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  width: 200px;
  color: #062550;
  line-height: 20px;
}

.add-new-guest-aag33 {
  border: none !important;
  box-shadow: none !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  padding-left: 8px !important;
  margin-top: 10px;
}

.add-new-guest-aag33:hover {
  background: none !important;
}

.pricing-main {
  width: 50%;
  text-align: end;
  font-size: 17px;
  font-weight: bold;
  line-height: 20px;
}

/* .budget-main .accordion {
  height: 600px !important;
  overflow-y: scroll;
  scrollbar-width: none;
} */
.main-accordion-for-all-class-12 {
  height: 800px !important;
  overflow: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  margin-top: 15px;
}

.new-about-us-img img {
  height: 500px;
  width: 100%;
  object-fit: fill;
}

.new-about-us-img {
  position: relative;
  background-image: url(../../assets/images/new-about-us-image.jpg);
  height: 500px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-about-us-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  opacity: .7;
  z-index: 0;
}

button.ant-btn.ant-btn-default.select-inn.main-budgut-444 {
  /* width: 93px; */
  height: 43px;
  border-radius: 60px;
  margin-left: 10px;
  border: none;
  font-size: 17px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #CE9944;
  line-height: 20px;
  padding: 10px 27px;
  color: #021c3f;
}

.about-us-discripton.about-us-discripton-new-descriotion {
  border-radius: 10px !important;
  margin-bottom: 20px;
}

button.ant-btn.ant-btn-default.select-inn.main-budgut-444:hover {
  background: #ce9944 !important;
}

div#navbarSupportedContent {
  justify-content: end;
}

div#main-3 button {
  font-weight: 600;
  font-size: 17px;
  line-height: 28px;
  background: #021c3f;
  color: #ffffff;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 15px;
  padding: 5px 10px;
}

div#main-3 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
}

.new-about-us-img h4 {
  font-size: 45px;
  color: #fff;
  z-index: 999;
  font-weight: 800;
}

.main-quotation-detailss.main-quotation-detailss-main-newww {
  border-radius: 20px !important;
  margin: 20px 0px;
}

.add-price.color-danger {
  text-align: end;
}

.main-mmount-44 {
  display: grid;
  gap: 15px;
}

.main-mmount-44 .add-price {
  color: #021c3f;
  display: flex;
  align-items: center;
}

/* .budget-main .accordion {
  height: 600px !important;
  overflow-y: scroll;
  scrollbar-width: none;
} */

.main-add-for-scroll {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100% !important;
  background: white;
  border-top: 1px solid #DCDCDC;
  box-shadow: 0px 5px 30px 0px #00000040;
  padding: 5px 10px;
  border-radius: 15px 15px 0px 0px;
}

.add-iemes.main-add-for-scroll {
  display: block !important;
}

.for-quotation-background-3 {
  background: white !important;
  border-radius: 10px;
  padding: 10px;
  height: 200px;
  overflow: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}

.main-quotation-detailss .pdf-main img.ant-image-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

input#event_id {
  height: 100% !important;
  background: transparent !important;
}

.quotation-sub-img img.ant-image-img {
  width: 115px !important;
  height: 115px;
  border-radius: 50%;
  object-fit: cover;
  width: 100%;
}

button.ant-btn.ant-btn-default.quotation-icons {
  background-color: transparent;
  width: 45px;
  height: 45px;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

button.ant-btn.ant-btn-default.quotation-icons img {
  width: 32px !important;
  height: 32px !important;
  flex: 0 0 32px;
}

a.ant-typography.ant-dropdown-trigger.languages-icon img {
  width: 32px !important;
  height: 32px !important;
  flex: 0 0 32px;
}

.notificationDropdownMain button {
  background: transparent;
  height: 45px;
  padding: 5px;
  width: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.notificationDropdownMain button img {
  width: 32px;
  height: 32px;
  flex: 0 0 32px;
}


.tab_modal .ant-modal-close {
  display: none;
}

.ant-modal.tab_modal .ant-modal-content {
  border-radius: 30px;
  box-shadow: 0 0 10px #00000021;
  overflow: hidden;
}

p.no-quotation-3 {
  margin: 0;
  font-size: 22px;
  padding: 15px 0px;
  font-weight: 500;
  line-height: 25px;
  text-align: center;
}

.gmail-box input#email,
.gmail-box-1 input#email {
  background: transparent !important;
}

.modal_title_cls {
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--theme2);
  margin-bottom: 30px;
}

.modal_sub_title_cls {
  font-family: Visby Round CF;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.3;
  text-align: center;
  color: var(--theme2);
}

.ant-modal.tab_modal .ant-modal-body {
  padding: 0 !important;
}


.ant-modal.tab_modal .ant-modal-footer {
  border: 0;
  padding: 0;
  text-align: center;
  padding-top: 25px;
}

.ant-modal.tab_modal .ant-modal-footer .ant-btn {
  min-height: 52px;
  border-radius: 60px !important;
  padding: 13px 30px !important;
  min-width: 130px;
  box-shadow: 0px 9px 36px 0px #7A81BE29;
  color: var(--theme2) !important;
  font-family: Visby Round CF;
  font-size: 15px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  background-color: transparent !important;
}

.ant-input::-ms-input-placeholder,
input::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #00000075 !important;
  font-size: 13px;
}

.ant-input::placeholder,
input::placeholder {
  color: #00000075 !important;
  font-size: 13px;
}

.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  color: #00000075;
  font-weight: 500;
  font-size: 13px;
}

.ant-modal.tab_modal .ant-modal-footer .ant-btn.ant-btn-primary {
  background: var(--theme1) !important;
  border-color: var(--theme1) !important;
  text-transform: uppercase !important;
  letter-spacing: 0.08em !important;

}

.ant-modal.tab_modal .ant-modal-footer button.ant-btn.ant-btn-default {
  border: solid 1px var(--theme2) !important;
  background: transparent !important;
  text-transform: uppercase !important;
  letter-spacing: 0.08em !important;

}

button.ant-btn.ant-btn-primary.leave-feedback:hover {
  background-color: #F2C230 !important;
  color: #021C3F !important;
  border-color: #F2C230 !important;
}

.ant-modal.tab_modal .ant-modal-footer button.ant-btn.ant-btn-default:hover {
  background: var(--theme2) !important;
  border-color: var(--theme2) !important;
  color: #000 !important;
}

.ant- modal.tab_modal .ant-modal-footer .ant-btn.ant-btn-primary:hover {
  border: solid 1px var(--theme1Dark) !important;
  background-color: var(--theme1Dark) !important;
  color: var(--theme2) !important;
}

.ant-modal.tab_modal .ant-form label span {
  font-family: Visby Round CF;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #F2C230 !important;
}

.ant-radio-inner::after {
  background-color: #F2C230 !important;
}

.ant-modal.tab_modal .ant-modal-content .upload_wrap .ant-image img.ant-image-img+.ant-image-mask,
.ant-modal.tab_modal .ant-modal-content .upload_wrap .ant-image img.ant-image-img {
  border-radius: 50% !important;
  overflow: hidden !important;
}

.ant-modal.tab_modal.edit_orders .ant-modal-content {
  padding: 28px;
}


.tab_modal .ant-modal-close {
  display: none;
}

.ant-modal.tab_modal .ant-modal-content {
  border-radius: 30px;
  box-shadow: 0 0 10px #00000021;
  padding: 20px;
}

.review-rating {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.header-rettings {
  margin-bottom: 20px;
}

.rating-summary {
  display: flex;
}

.rating-value {
  text-align: center;
  margin-right: 20px;
}

.rating-value h1 {
  font-size: 3rem;
  margin: 0;
  color: #021C3F;
}

.rating-value p {
  margin: 0;
}

.rating-breakdown {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.rating-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.rating-item span {
  margin-right: 10px;
}

.rating-item .bar {
  max-width: 180px;
  width: 100%;
  height: 10px;
  background-color: #D2D2D2;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 10px;
}

.rating-value .ant-rate {
  font-size: 14px;
  color: #F3B000;
}

.filled {
  height: 100%;
  background-color: #021C3F;
}

.leave-feedback {
  align-self: flex-start;
}

.tabs {
  margin-bottom: 20px;
}

.tabs button {
  padding: 10px 20px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  border-radius: 5px;
}

.ant-tabs.ant-tabs-top.ant-tabs-mobile.message-maaxx {
  margin-bottom: 20px;
}

.ant-modal.full-width-modal.tab_modal.deleteWarningModal.eleteWarningModal-hhht5657 .ant-modal-footer button.ant-btn.ant-btn-default:hover {
  background: none !important;
}

.tabs button.active {
  background-color: #021C3F;
  color: #fff;
}

.ant-tabs.ant-tabs-top.rate-tab-main .ant-tabs-nav-list {
  width: 100%;
  justify-content: space-around;
}


.rate-tab-main .ant-tabs-tab.ant-tabs-tab-active {
  text-shadow: none;
  background: #062550;
  color: #fff !important;
}

.rate-tab-main .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffffff;
  text-shadow: none;
}

.rate-tab-main .ant-tabs-tab {
  max-width: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #073C51;
  font-size: 18px;
  font-weight: 600;
  background-color: #62a4df2b;
  border-radius: 10px;
  height: 40px;
}

.list-rettings ul.ant-list-items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 40px;
}

li.ant-list-item.ant-list-item-no-flex.list-items-reteing {
  border-bottom: 1px solid #ccc;
}

.headding-reting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
  flex-wrap: wrap;
}

button.ant-btn.ant-btn-primary.leave-feedback {
  max-width: 200px;
  width: 100%;
  height: 50px;
  border: none;
  background: #021c3f;
  border-radius: 10px;
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
  color: #f2c230;
}

.headding-reting h6 {
  margin: 0;
  font-size: 30px;
  line-height: 32px;
  font-weight: 600;
}

h4.ant-list-item-meta-title span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.ant-rate {
  width: 100% !important;
  flex: 0 0 100%;
}

.ratting-points {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 18px;
}

ul.ratting-icons li {
  margin-right: 3px !important;
}

ul.ratting-icons {
  font-size: 14px;
}

span.rate-count {
  color: #062550;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

span.rate-last-count {
  color: #A5A5A5;
  font-size: 16px;
  font-weight: 500;
}

li.ant-list-item.ant-list-item-no-flex.list-items-reteing h4 {
  margin: 0px;
}

li.ant-list-item.ant-list-item-no-flex.list-items-reteing .ant-list-item-meta {
  margin-bottom: 10px;
}

.ant-tabs.ant-tabs-top.rate-tab-main .ant-tabs-tab:hover {
  color: #000000;
}

.ant-tabs-tabpane.ant-tabs-tabpane-active.rate-tab {
  padding-top: 0px;
}

/* src/ReviewForm.css */

.review-form {
  text-align: center;
}

.review-form .reviewheader {
  margin-bottom: 20px;
}

.review-form .logo {
  width: 100%;
  height: 150px;
  max-width: 150px;
  border-radius: 50%;
}

.reviewheader h2 {
  font-size: 24px;
  margin: 10px 0px;
  color: #021C3F;
}

.reviewheader p {
  color: #A7A7A7;
  font-size: 18px;
}

.events-review-tital {
  max-width: 65%;
  width: 100%;
  margin: 0 auto;
  color: #021C3F;
}

.review-stars {
  padding: 10px;
  border-top: 1px solid #E6E6E6;
  border-bottom: 1px solid #E6E6E6;
}

.review-form .star {
  font-size: 32px;
  cursor: pointer;
  color: #FECB00;
}

.review-form .star.selected {
  color: #F3B000;
}

.review-stars p {
  margin: 0px;
  font-size: 18px;
}

.review-rename label {
  color: #073C51;
  font-size: 16px;
  font-weight: 500;
}

.ant-modal.tab_modal .ant-modal-footer button.ant-btn.submit-button {
  background-color: #021C3F !important;
  color: #F2C230 !important;
  border-color: #021c3f !important;
  border-radius: 14.9px !important;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.ant-modal.tab_modal .ant-modal-footer button.ant-btn.submit-button:hover {
  background-color: #F2C230 !important;
  color: #021C3F !important;
  border-color: #F2C230 !important;
}

.pageHeadingSearch {
  border: 1px solid #021c3f1f;
  height: 48px !important;
  border-radius: 12px;
  background: #f6f7fb !important;
  font-size: 17px;
  line-height: 19px;
  font-weight: 500;
  color: #021c3f;
  padding: 0px 20px;
  letter-spacing: 2px;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 19.37px 0px #0000001A;
}

.pageHeadingSearch input.ant-input {
  background: #f6f7fb !important;
}

.Noquotation33Img img {
  width: 500px;
}

.Noquotation33Img {
  text-align: center;
}

.no-noti-data img {
  width: 500px;
}

.no-noti-data {
  text-align: center;
}

button.ant-btn.ant-btn-default.notifiaction-buttin-main {
  background: none !important;
  border: none;
  padding: 0px;
  position: relative;
}

button.ant-btn.ant-btn-default.notifiaction-buttin-main img {
  width: 30px;
  height: 30px;
}

span.active_notification.active_notification-999 {
  position: absolute !important;
  background: red;
  width: 19px;
  height: 19px;
  top: 0px;
  right: 0px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  line-height: 14px;
  color: white;
  font-weight: 500;
}

.mani-text-notification902 {
  font-size: 30px;
  font-weight: 600;
  color: #021c3f;
}

button.ant-btn.ant-btn-default.quotation-icons.quotation-icons-main95h img {
  filter: invert(1);
}

.remamber-1.remamber-1-mani {
  background: white;
  padding: 25px;
  margin: 20px 0px;
  border-radius: 30px;
}

.pageHeadingSearch .searchInput span.ant-input-affix-wrapper {
  border: 0px solid #D9D9D9;
  height: 42px;
  border-radius: 7px;
  background: #f6f7fb !important;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: #A7A7A7;
  padding: 0px;
  letter-spacing: 2px;
  width: 100% !important;
  box-shadow: none;
}

.pageHeadingSearch .searchInput span.ant-input-group-addon {
  background-color: #f6f7fb;
}

.pageHeadingSearch .searchInput button.ant-btn.ant-btn-default.ant-btn-icon-only.ant-input-search-button {
  border: none;
  background-color: transparent;
}

button.ant-btn.ant-btn-primary.submit-button {
  height: 50px;
  border-radius: 60px;
  padding: 0 25px;
  border: 1px solid #062550;
  background: #062550 !important;
  max-width: -moz-fit-content;
  color: #fff;
  max-width: fit-content;
  width: 100%;
  font-size: 16px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
}

button.ant-btn.ant-btn-default.back-button {
  height: 50px;
  border-radius: 60px;
  padding: 0 25px;
  background: #fff !important;
  color: #062550;
  max-width: fit-content;
  border: 1px solid #062550;
  width: 100%;
  font-size: 16px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}



.ant-form-item.upload_wrap button.ant-btn.ant-btn-default {
  border: 1px solid #D9D9D9;
  height: 55px;
  border-radius: 7px;
  background: white;
  font-size: 16px;
  line-height: 40px;
  font-weight: 400;
  color: #A7A7A7;
  letter-spacing: 2px;
  width: 100% !important;
}

.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 7px;
}

.ant-select.button-firsttt.ant-select-single.ant-select-show-arrow .ant-select-selector span.ant-select-selection-item {
  color: #000 !important;
  font-size: 17px;
  font-weight: 500;
}

.radio-rate {
  display: flex;
  align-items: self-end;
  gap: 3px;
  font-size: 16px;
  padding-left: 8px;
  line-height: 18px;
  font-weight: 500;
}

.galleryhome-uinn h3 {
  margin: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  color: white;
  z-index: 20;
}

.galleryhome-uinn {
  position: absolute;
  bottom: 10px;
  z-index: 10;
  left: 0;
  right: 0;
}

.galleryhome-uinn span {
  margin: 0;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: white;
  width: 100%;
  max-width: 100%;
  display: block;
}

.gallery-items02 {
  border-radius: 10px;
  overflow: hidden;
}

.gallery-items02 .ant-image {
  width: 100%;
}

.gallery-items02 img {
  width: 100%;
  height: 200px;
  max-width: 100%;
  object-fit: cover;
}

.ant-radio-group.ant-radio-group-outline.radio-g-check,
.ant-checkbox-group.radio-g-check {
  display: flex;
}

textarea.ant-input {
  width: 100%;
  padding: 10px 10px;
  border-radius: 7px;
  border: 1px solid #d9d9d9;
}

.headding-modal-h span {
  font-size: 16px;
  font-weight: 700;
}

.heart-image span {
  font-size: 30px;
}

.for-like-share img {
  width: 40px;
  height: 40px;
}

/* ----------------------------------------------- */

.ant-select.main-bride-guest.ant-select-single.ant-select-show-arrow {
  max-width: 279px;
  width: 100%;
  height: 48px !important;
}

.main-bride-guest .ant-select-selector {
  height: 48px !important;
  box-shadow: 0px 0px 12px 0px #0000001A;
  border-radius: 12px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-share-bttnn button.ant-btn.ant-btn-default.ant-btn-icon-only {
  width: 48px;
  height: 48px;
  border-radius: 12px;
  box-shadow: 0px 0px 12px 0px #0000001A;
  margin-left: 13px;
  background: #062550;
  color: white;
}

.main-share-bttnn {
  text-align: end;
}

.main-all-div-guestlist {
  background: white;
  padding: 15px;
  border-radius: 17px;
}

.guest-list-divvv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
}

.guest-list-divvv span.ant-typography {
  color: #021C3F;
  font-weight: 6 00 !important;
  font-size: 20px;
  line-height: 25px;
}

.invite-button-main {
  width: -moz-fit-content;
  width: fit-content;
  height: 35px !important;
  box-shadow: 0px 0px 1px 0px #FFFFFF4D !important;
  border-radius: 10px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  margin: 0 !important;
  background: #DFEEFF !important;
  border: none !important;
  color: black !important;
  padding: 2px 7px !important;
}

.offer-img.offer-img-offer-img-new-man3 img {
  background: linear-gradient(#ffffff, #ffffff) padding-box, #062550cf border-box !important;
  border: 2px solid transparent;
  padding: 4px;
}

.invite-button-main:hover {
  width: fit-content;
  height: 35px !important;
  box-shadow: 0px 0px 1px 0px #FFFFFF4D;
  border-radius: 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  margin: 0 !important;
  background: #DFEEFF;
  border: none;
  color: black !important;

}

.noevent-text form.ant-form.ant-form-inline {
  display: flex;
  align-items: baseline;
}

button.ant-btn.ant-btn-primary.invite-button-main.for-blluee-colorr {
  background: #021C3F !important;
  color: #F2C230 !important;
  padding: 2px 8px;
}

.ant-list.ant-list-split.ant-list-bordered.main-div-of-all-list {
  box-shadow: 0px 0px 12px 0px #0000001A;
  background: white;
  border-radius: 12px;
  border: none;
}

.guestname-44 h4 {
  font-size: 16px;
  font-weight: 500;
  color: black;
  margin: 0;
  padding-bottom: 8px;
}

li.ant-list-item.guest-list-name-all .ant-select-selector,
li.ant-list-item.guest-list-name-all input.ant-input {
  box-shadow: 0px 0px 0px 0px #00000026 !important;
  border: 1px solid #EDF1FF !important;
  background: #F6F7FB !important;
  border-radius: 15px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px !important;
  min-width: 200px !important;
  width: 100%;
}

li.ant-list-item.guest-list-name-all {
  display: block;
}

.new-active-img img {
  max-width: 140px !important;
  width: 100%;
}

.new-active-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerSearch.d-none.d-lg-block input.form-control {
  padding-left: 50px !important;
  color: black !important;
  font-weight: 500 !important;
}

.panel-heading.main-filter-btn-main-45 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #00000059;
}

.filter-bg .panel-heading button div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.for-filterbutton-drowerss button {
  background: #021c3f !important;
  border: none;
  box-shadow: none !important;
  margin: 0;
  float: left !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #0d2444;
  height: 100%;
  padding: 5px 0px !important;
  width: 45px !important;
  height: 45px !important;
  border-radius: 5px !important;
}

.for-filterbutton-drowerss button img {
  filter: invert(6);
}

button.filter-button-reset.filter-button-reset-44.btn.btn-primary {
  background: none !important;
  border: none;

  box-shadow: none !important;
  margin: 0;
  padding: 0 !important;
  float: left !important;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #0d2444;
  height: 100%;
  padding: 0px 0px !important;
}

.ant-drawer.ant-drawer-left.ant-drawer-open .ant-drawer-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.panel.panel-default.main-filter-btn-main-145.main-filter-btn-main-146 {
  padding: 0px !important;
}

.ant-drawer-header {
  align-items: center !important;
}

button.ant-btn.ant-btn-primary.filter-button-reset.filter-button-reset-44 {
  gap: 0px !important;
  border-color: #021c3f;
}

/* .navbar-collapse {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  background-color: #fff;
  transition: transform 0.3s ease-in-out;
} */

/* .navbar-expand-lg .navbar-collapse {
  transform: translateX(-100%);
}

.navbar-expand-lg .navbar-collapse.show {
  transform: translateX(0);
} */


span.css-1u9des2-indicatorSeparator {
  display: none;
}

.country-wrap {
  position: relative;
  min-width: 120px;
}

.css-13cymwt-control {
  background: #f9f9f9 !important;
  border: none !important;
  outline: none !important;
}

.language-select {
  width: 150px;
  font-size: 14px;
}

.language-select .ant-select-selector {
  height: 36px !important;
  display: flex;
  align-items: center;
  padding: 0 10px !important;
  border-radius: 8px !important;
  border: 1px solid #d9d9d9 !important;
  transition: all 0.3s;
}

.language-select .ant-select-selection-item {
  line-height: 36px;
}

.language-select:hover .ant-select-selector {
  border-color: #40a9ff !important;
}

.language-select:focus .ant-select-selector {
  border-color: #40a9ff !important;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2) !important;
}

.language-select .ant-select-dropdown {
  font-size: 14px;
  border-radius: 8px;
}

.language-select .ant-select-item {
  padding: 10px 12px;
}

.language-select .ant-select-item-option-selected {
  background-color: #e6f7ff !important;
}

.language-select .ant-select-item-option-active {
  background-color: #f5f5f5 !important;
}

.css-1nmdiq5-menu {
  z-index: 200000 !important;
}

.remamber-teext {
  width: 100%;
}

/* ------------------------------------------------------------------ */
.ant-collapse.ant-collapse-icon-position-start {
  border: none !important;
  background: none !important;
  overflow-y: scroll;
  height: 600px;
  scrollbar-width: none;
}

.ant-collapse>.ant-collapse-item {
  border: 1px solid #EEF5FF !important;
  border-radius: 15px !important;
  box-shadow: 0px 0px 5px 0px #0000001A;
  background: #EEF5FF;
  overflow: hidden;
  margin-bottom: 15px;
}

.story-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  /* Adds a background overlay */
  z-index: 1000;
}

.story-modal img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  /* Ensures the image covers the container without stretching */
}

.ant-collapse-header {
  height: 68px;
  display: flex;
  align-items: center !important;
}

span.ant-collapse-header-text h2 {
  margin: 0;
  font-size: 22px !important;
  line-height: 18px !important;
  font-weight: 5 00 !important;
  color: #021C3F;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  display: inline-block;
  margin-right: 12px;
  font-size: 20px !important;
  vertical-align: -1px;
  margin-right: 20px;
}

span.ant-collapse-header-text h1 {
  margin: 0;
}

button.google-button-img {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 10px;
  font-size: 17px;
  font-weight: 600;
  line-height: 22px;
  color: #021c3f;
}

.remamber-para.remamber-para-main9 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}


.main-delete-out span {
  width: 30px;
  height: 30px;
  background: #021c3f;
  color: white;
  border-radius: 333px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
}

.mani-text-notification902main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}



.main-notification-of-content {
  font-size: 18px;
  line-height: 20px;
  word-break: break-all;
  padding-right: 15px;
  font-weight: 500;
}

.deleteall-main-button {
  background: #021c3f !important;
  color: #f2d377 !important;
  border: 1px solid #021c3f !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  border-radius: 8px !important;
}

.main-dltall-not-all {
  display: flex;
  align-items: center;
  gap: 5px;
}



.file-input-labelmain-45 img {
  width: 18px;
  height: 18px;
}

.remamber-main.Notification-unread {
  background: #e7ecff;
}

.no-active_event.no-active_event-main99 h2 {
  font-size: 42px;
  font-weight: 600;
  color: #062550;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 15px;
}

.no-active_event.no-active_event-main99 p {
  font-size: 40px;
  padding-top: 15px;
  padding-bottom: 25px;
  font-weight: 600;
  color: #062550;
}

.create-event-main-button-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-event-main-button-div button {
  width: 100%;
  max-width: 250px;
  border-radius: 12px !important;
  background: #062550 !important;
  color: #e99713 !important;
  height: 50px !important;
  font-size: 20px !important;
  font-weight: 600 !important;
}

.no-active_event.no-active_event-main99 .event-backgg img {
  margin-bottom: 40px;
}

.story-container {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 40px;
}

.scroll-bar {
  padding: 20px 0px;
  border-bottom: 1px solid #062550;
  margin-bottom: 15px;
}

.story-item {
  display: grid;
  gap: 10px;
}

.scroll-bar .avatar-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scroll-bar span.ant-avatar.ant-avatar-circle.ant-avatar-image {
  width: 70px;
  height: 70px;
}

.scroll-bar span.man-34span {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
}

.mian-new-reply-story-new-main {
  display: flex;
  justify-content: end;
  width: 100%;
}

.thumbnail video, .thumbnail img {
  width: 100%;
  height: 100%;
}

.thumbnail .ant-image {
  height: 100%;
}

.mian-new-reply-story {
  background: #021c3f;
  width: fit-content;
  display: grid;
  place-content: end;
  justify-content: end;
  border-radius: 20px 0px 20px 20px;
  padding: 8px;
  color: white;
  font-size: 15px;
  text-align: left;
}

.ant-modal.tab_modal.deleteWarningModal.eleteWarningModal-hhht5657.eleteWarningModal-hhht5657ooo .ant-modal-content {
  box-shadow: 0 0 10px #00000021 !important;
  padding: 0px !important;
  border-radius: 20px !important;
  overflow: hidden;
}

.message-container {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 5px;
  left: 14px;
  z-index: 999;
  right: 20px;
}

.message-container input.ant-input.message-input {
  max-width: 100%;
  height: 40px;
  border-radius: 12px;
  padding: 20px;
  margin: 0px 7px;
  width: 100%;
}


.message-container button.ant-btn.ant-btn-circle.ant-btn-default.ant-btn-icon-only.icon-button span.anticon.anticon-heart {
  color: red;
  font-size: 23px;
}

.message-container button.ant-btn.ant-btn-circle.ant-btn-primary.ant-btn-icon-only.icon-button {
  width: 48px;
  height: 48px;
  background: #062550;
  border-radius: 50%;
  border: none;
  margin: 0;
  margin-left: 10px;
}

.message-container button.ant-btn.ant-btn-circle.ant-btn-primary.ant-btn-icon-only.icon-button span.anticon.anticon-send {
  color: white;
  font-size: 20px;
}

.eleteWarningModal-hhht5657 .ant-modal-content {
  overflow: hidden !important;
  position: relative;
  padding: 0px !important;
}

.eleteWarningModal-hhht5657 button.ant-btn.ant-btn-primary {
  display: none;
}

.eleteWarningModal-hhht5657 .ant-modal-footer {
  position: absolute;
  top: 0px !important;
  right: 0;
  z-index: 2000;
}

.ant-modal.full-width-modal.tab_modal.deleteWarningModal.eleteWarningModal-hhht5657 button.ant-btn.ant-btn-default {}

.ant-modal.full-width-modal.tab_modal.deleteWarningModal.eleteWarningModal-hhht5657 .ant-modal-footer button.ant-btn.ant-btn-default {
  border: none !important;
  box-shadow: none !important;
  padding: 0px !important;
  justify-content: right !important;
  padding-right: 15px !important;
  display: flex;
  align-items: center;
}

span.anticon.anticon-arrow-right.main-back--button-csss {
  color: white;
  font-size: 22px;
}

.eleteWarningModal-hhht5657 .ant-modal-footer {
  padding: 10px !important;
}



.result-headding h4 {
  margin: 0;
}

.ant-select-selector {
  display: flex;
  align-items: center;
}

.ant-slider-track {
  background-color: #021c3f !important;
}

.ant-slider-handle {
  background-color: #021c3f !important;
  border: solid 2px #e9bb92 !important;
}

/* .ant-select.select-inn.main-budgut-444.ant-select-single.ant-select-show-arrow .ant-select-selector {
  height: 42px !important;
  border-radius: 9px !important;
} */

.ant-select.select-inn.main-budgut-444.ant-select-single.ant-select-show-arrow .ant-select-selector span.ant-select-selection-item {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
}

.model-form-main-1 span.ant-input-affix-wrapper.ant-input-password.ant-input-affix-wrapper-status-success,
.account-outers span.ant-input-affix-wrapper.ant-input-password.ant-input-affix-wrapper-status-success {
  padding: 0px !important;
  overflow: hidden;
}

.model-form-main-1 input#password,
.account-outers input#old_password,
.account-outers input#new_password,
.account-outers input#confirm {
  padding: 0px 20px;
}

.model-form-main-1 span.ant-input-suffix,
.account-outers span.ant-input-suffix {
  margin: 0;
  background: #021c3f;
  width: 40px;
  height: 100%;
}

.main-new-select-lost-view {
  display: flex;
  align-items: center;
  gap: 10px;
}

span.ant-input-affix-wrapper.ant-input-password,
.account-outers span.ant-input-affix-wrapper.ant-input-password {
  padding: 0px !important;
  overflow: hidden;
}

.model-form-main-1 span.anticon.anticon-eye-invisible.ant-input-password-icon,
.model-form-main-1 span.anticon.anticon-eye.ant-input-password-icon,
.account-outers span.anticon.anticon-eye-invisible.ant-input-password-icon,
.account-outers span.anticon.anticon-eye.ant-input-password-icon {
  color: #f2d377;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.model-form-main-1 span.ant-input-affix-wrapper.ant-input-password.ant-input-affix-wrapper-status-error,
.account-outers span.ant-input-affix-wrapper.ant-input-password.ant-input-affix-wrapper-status-error {
  padding: 0px;
  overflow: hidden;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  background-color: red !important;
}

.ant-select-selector {
  border-radius: 7px !important;
  /* box-shadow: 0px 0px 2px 0px rgb(2 28 63 / 25%) !important; */
  display: flex;
  align-items: center;
}

.ant-select.ant-select-multiple .ant-select-selector {
  min-height: 48px;
  height: 100% !important;
}

.noevent-textmain-987gdgd .ant-form-item-control-input {
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}

.noevent-textmain-987gdgd input#name,
.noevent-textmain-987gdgd input#email {
  border-radius: 10px !important;
  box-shadow: 0px 8.3px 62.24px 0px #00000033;
}

.main-invite-all-text-jjh {
  font-size: 22px;
  font-weight: 700;
  color: #062550;
  padding: 15px 0px;
}

span.edit-iconee button.ant-btn.ant-btn-default:hover {
  background: none !important;
}

span.edit-iconee button.ant-btn.ant-btn-default {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  width: 100%;
}

.ant-modal.noevent-model.noevent-modelmain-uuui-hhh-kkll .event-backgg-header h2 {
  padding-bottom: 10px !important;
}

.ant-modal.tab_modal.second-modal.second-modalmain000 .ant-modal-content,
.ant-modal.tab_modal.second-modal.main-three .ant-modal-content {
  padding: 0px !important;
}

/* General Styling for the Gallery Section */
.gallery-section-4 {
  padding: 20px;
  background-color: #f9f9f9;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

/* Styling for Gallery Text */
.gallery-text-1 {
  text-align: center;
  margin-bottom: 20px;
}

.gallery-img3 {
  flex: 1 1 calc(25% - 10px);
  box-sizing: border-box;
  position: relative;
}

.gallery-img3 img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}



.gallery-main-img.gallery03 .gallery-img3 {
  flex: 1 1 calc(33.33% - 10px);
}

/* Button Styling */
.button-main22 {
  text-align: center;
  margin-top: 20px;
}

.show-more02 {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.show-more02 img {
  margin-left: 10px;
  transition: transform 0.3s ease;
}

/* Button Hover Effect */
.show-more02:hover {
  background-color: #0056b3;
}

.show-more02:focus {
  outline: none;
}

.show-more02 img {
  transform: rotate(0deg);
}

/* Show/Hide Extra Content Animation */
.gallery-main-img {
  transition: max-height 0.3s ease;
}



.gallery-main-img.gallery03.show {
  max-height: 1000px;
  /* Adjust based on content height */
}

.search-main {
  position: relative;
}

.search-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  background-color: #fff;
}

.search-input {
  border: none;
  outline: none;
  flex: 1;
}

.search-button {
  background: none;
  border: none;
  cursor: pointer;
}

.search-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  padding: 15px;
}

.title-search {
  font-weight: bold;
  margin-bottom: 10px;
}

.category-list {
  list-style-type: none;
  padding: 0;
}

.category-list li {
  margin-bottom: 5px;
}

.link-search {
  color: #000;
  text-decoration: none;
}

.owl-searchbar .item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-img01 img {
  width: 100px !important;
  height: auto;
}

.product-title {
  text-align: center;
  margin-top: 10px;
}

.product-title a {
  color: #000;
  text-decoration: none;
}

.search-container input#headerSearch {
  padding-left: 35px;
}

.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light {
  min-width: 180px;
  z-index: 200;
}

.ant-form-item.filtter-select.main-new-color-attributes .ant-row.ant-form-item-row {
  flex-direction: unset !important;
  flex-flow: unset !important;
  width: 100%;
  align-items: center;
  gap: 20px;
}

span.ant-input-affix-wrapper.ant-input-password.ant-input-affix-wrapper-status-success.ant-input-affix-wrapper-has-feedback span.anticon.anticon-eye-invisible.ant-input-password-icon {
  margin: 0px !important;
}

h5.new-attribute-tag {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  margin-bottom: 10px;
}

form.ant-form.ant-form-vertical.p-2 h4.modal_title_cls {
  justify-content: center;
}

.ant-form-item.upload_wrap.upload_wrapmain-newww.ant-form-item-has-success .ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-picture {
  border-radius: 12px !important;
  height: 50px;
}

.ant-form-item.filtter-select.main-new-color-attributes .ant-input-number.ant-input-number-in-form-item {
  width: 100%;
  border-radius: 10px !important;
  overflow: hidden;
}

.ant-form-item.filtter-select.main-new-color-attributes label.ant-radio-wrapper.ant-radio-wrapper-in-form-item span {
  display: flex;
  align-items: center;
}

.ant-form-item.upload_wrap.ant-form-item-has-success .ant-upload.ant-upload-select.ant-upload-select-picture {
  max-width: 300px !important;
  width: 100%;
  border-radius: 10px !important;
  overflow: hidden;
  border: 1px solid #bbb;
  height: 50px;
}

.ant-col.ant-col-24.mt-3.Send-Requestmain-biutton button {
  max-width: 300px;
  width: 100%;
  height: 50px !important;
  border-radius: 15px !important;
}

.ant-form-item.upload_wrap.ant-form-item-has-success .ant-upload.ant-upload-select.ant-upload-select-picture button.ant-btn.ant-btn-default {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100% !important;
  border: none !important;
}

.Send-Requestmain-biutton {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.ant-form-item.filtter-select.main-new-color-attributes .ant-col.ant-form-item-label {
  max-width: 200px;
  font-weight: 600;
  width: 100%;
}

.container.for-all-short-w .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
  overflow: visible !important;
}

.ant-form-item.upload_wrap.upload_wrapmain-newww button.ant-btn.ant-btn-default {
  width: 100% !important;
  min-width: 270px !important;
  border-radius: 10px !important;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-new-color-attributes .ant-select-selector {
  height: 45px !important;
}

.new-main-class-ofServicemain h5 {
  font-weight: 600;
  font-size: 14px;
}

.new-main-class-ofServicemain h6 {
  font-size: 14px;
}

.new-main-class-ofServicemain {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
}

.event-happy-emoji.emogi-event {
  position: unset !important;
}

.no-eve-modal img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.noevent-text.noheadding h2 {
  font-size: 30px;
  font-weight: 600;
  color: #062550;
  margin: 0;
  padding-top: 0px;
  padding-bottom: 15px;
}

.noevent-text.noheadding p {
  font-size: 26px;
  padding-top: 0px;
  padding-bottom: 25px;
  font-weight: 600;
  color: #062550;
}

.offer-img.offer-img-offer-img-new .ant-image {
  width: 100%;
}

.ant-tabs.ant-tabs-top.budget-main-hddr .ant-tabs-nav {
  margin-bottom: 0px;
}

.ant-input-number.ant-input-number-in-form-item.number-inputs {
  width: 100%;
  border: none;
  box-shadow: none;
}

.ant-form-item.input-mb-o {
  margin-bottom: 10px;
}

.gmail-box-1 .ant-form-item-with-help .ant-form-item-explain {
  position: absolute;
}

.styles-module_wrapper__1I_qj {
  background-color: #000000cf !important;
}

#ReactSimpleImageViewer .styles-module_close__2I1sI {
  color: white;
  position: absolute;
  top: 0px;
  right: 25px;
  font-size: 58px;
  font-weight: 500;
  opacity: 1;
  cursor: pointer;
}

.ant-form-item.categ-menus {
  margin-bottom: 0px;
}

.category-menubar label.ant-checkbox-wrapper {
  font-weight: 600;
}

.ant-divider.ant-divider-horizontal.categ-dri {
  margin-top: 0;
  border-top: 1px solid rgb(220, 220, 220);
}

.cls-redios p {
  margin: 0;
}

.cls-redios {
  display: flex;
  align-items: center;
}

.ant-form-item.num-inn-se .ant-input-number-handler-wrap {
  display: none;
}

.owl-carousel.owl-searchbar .owl-nav.disabled {
  display: block !important;
}

.owl-carousel.owl-searchbar .owl-prev {
  background: #fff url(../images/prev.png) !important;
  background-position: 50% !important;
  background-repeat: no-repeat !important;
  border: 1px solid #ccc;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  left: 0;
}

.owl-carousel.owl-searchbar .owl-next, .owl-carousel.owl-searchbar .owl-prev {
  border-radius: 50%;
  font-size: 0 !important;
  height: 30px;
  position: absolute;
  top: calc(50% - 25px);
  width: 30px;
}

.owl-carousel.owl-searchbar .owl-next {
  background: #fff url(../images/next.png) !important;
  background-position: 50% !important;
  background-repeat: no-repeat !important;
  left: auto !important;
  right: 0 !important;
  border: 1px solid #ccc;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.product-title a h6 {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.search-dropdown.search-dropdown-new-mainhh .product-title a p {
  font-size: 12px;
  font-weight: 400;
}

.features-area .avatar-sm {
  height: 3rem;
  width: 3rem;
}

.features-area .icon-text h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
}

.features-area .image-box {
  position: relative;
  box-shadow: 0 0px 2px 0px #0000001f;
  border-radius: 1rem;
  padding: 15px;
  height: 100%;
}

.features-area .icon-text p {
  font-size: 16px;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.features-area ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  list-style: none;
}

section.event-plan-sec {
  background-color: #062550;
  padding: 50px 0px;
  position: relative;
  overflow: hidden;
}

.invest__feature__wrapper {
  margin-top: 54px;
}

.invest__feature__item:first-of-type {
  margin-top: 0px;
}

.invest__feature__item {
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
}

.invest__feature__item__img img {
  width: 100%;
  height: 60px;
  border-radius: 0px;
  flex: 0 0 60px;
  max-width: 60px;
}

.invest__feature__item h6 {
  font-size: 22px;
  line-height: 45px;
  font-weight: 500;
  margin-bottom: 0px;
  color: #fff;
}

.invest__feature__item p {
  color: #959595;
  margin: 0px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.invest__feature__item__img {
  flex: 0 0 80px;
}

.invest__feature__item__content {
  display: block;
  width: 100%;
}

.event-p-page h3 {
  font-size: 42px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
  color: #ffffff;
  margin: 0px;
  padding-bottom: 10px;
}

.phone-img-inner {
  position: absolute;
  bottom: 0;
}

.phone-img-inner img {
  width: 100%;
}

.event-p-page p {
  color: #cbcbcb;
  margin: 0px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.event-p-page h6 {
  font-size: 20px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color: #ffffff;
  margin: 0px;
}

.vision-section {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: relative;
  padding: 50px 0px;
}

.vision-section .overlay {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vision-section .content {
  text-align: center;
  max-width: 700px;
  animation: fadeIn 1.5s ease-in-out;
}

.vision-section .heading {
  font-size: 42px;
  font-weight: 700;
  line-height: 51px;
  letter-spacing: 0em;
  color: #062550;
  margin: 0px;
  padding-bottom: 10px;
}

.paragraph {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  color: #062550;
}

.cta-button {
  background-color: #062550;
  color: #fff;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cta-button:hover {
  background-color: #062550;
  color: #fff;
  transform: scale(1.05);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.planning-start-sec {
  background-image: url(../images/backofinvtfrnd.png);
  background-size: cover;
  padding: 50px 0px;
  background-repeat: no-repeat;
  position: relative;
}

.planning-img img {
  max-width: 450px;
  width: 100%;
}

.typewriter span {
  font-size: 53px;
  font-weight: 700;
  line-height: 64px;
  color: #fff;
}

.offer-txt.provider-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.provider-offer-img .ant-carousel {
  max-width: 250px;
  height: 250px;
  width: 100%;
  border-radius: 20px !important;
  overflow: hidden;
}

img.ant-image-img.provider-carousel {
  max-width: 250px;
  height: 250px;
  width: 100%;
  border-radius: 20px !important;
  overflow: hidden;

}

.provider-offer-img .ant-carousel .slick-prev {
  width: 28px !important;
  height: 28px !important;
  color: #3F51B5 !important;
  background: url(../images/prev.png) !important;
  background-repeat: no-repeat !important;
  background-color: #d1d1d1 !important;
  border-radius: 50%;
  background-position: center !important;
  left: 0px !important;
  z-index: 9;
}

.provider-offer-img .ant-carousel .slick-next {
  width: 28px !important;
  height: 28px !important;
  color: #3F51B5 !important;
  background: url(../images/next.png) !important;
  background-repeat: no-repeat !important;
  background-color: #d1d1d1 !important;
  border-radius: 50%;
  background-position: center !important;
  right: 0px !important;
  z-index: 9;
}

.provider-offer-img {
  background: linear-gradient(#fff, #fff) padding-box, #ce9944 border-box !important;
  border: 3px solid #0000;
  border-radius: 20px !important;
  flex: 0 0 250px;
  height: 100%;
  max-width: 250px;
  min-height: 250px;
  padding: 3px;
  width: 100%;
}

.provider-offer-img .slick-slide .ant-image {
  width: 100%;
}

.offer-listedbar {
  margin-bottom: 70px;
  height: 150px;
  overflow: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}

span.color-selected {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 1px;
}

.selected-color-main {
  display: flex;
  align-items: center;
  gap: 7px;
}

.quote-image-select img {
  max-width: 50px;
  height: 50px;
}

.quets-search {
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

.quote-image-select {
  flex: 0 0 50px;
}

.quote-image-select .ant-image {
  width: 100%;
}

.quest-text p {
  font-size: 14px;
  margin: 0px;
  font-weight: 500;
}

.quest-text h6 {
  margin: 0;
}

.ant-select.ant-select-in-form-item.ant-select-status-success.form-select.form-select-newmain99.ant-select-single.ant-select-show-arrow.ant-select-show-search .quote-image-select img {
  width: 30px;
  height: 30px;
}

.ant-select.ant-select-in-form-item.ant-select-status-success.form-select.form-select-newmain99.ant-select-single.ant-select-show-arrow.ant-select-show-search .quest-text p {
  font-size: 11px;
  margin: 0px;
  font-weight: 500;
  line-height: 18px;
}

.ant-select.ant-select-in-form-item.ant-select-status-success.form-select.form-select-newmain99.ant-select-single.ant-select-show-arrow.ant-select-show-search .quest-text h6 {
  margin: 0;
  font-size: 14px;
}

.ant-select.ant-select-in-form-item.ant-select-status-success.form-select.form-select-newmain99.ant-select-single.ant-select-show-arrow.ant-select-show-search .quote-image-select {
  line-height: normal;
  flex: none;
}

.ant-select.ant-select-in-form-item.ant-select-status-success.form-select.form-select-newmain99.ant-select-single.ant-select-show-arrow.ant-select-show-search span.ant-select-selection-item {
  line-height: 0px !important;
}

.right-header-menu.right-header-menu-main-445 {
  margin-left: 15px;
}

.submit-button-mainnn {
  margin-top: 20px;

}


.pkg-src-show {
  display: flex;
  justify-content: flex-start;
  /* align-items: self-end; */
  justify-content: space-between;
  padding: -13px;
}

.message-card {
  width: 300px;
  padding: 10px;
  background-color: #021c3f;
  border-radius: 10px;
  color: white;
  font-family: Arial, sans-serif;
}

.main-new-chat-flex-option {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 5px;
  background: #474984d4;
  border-radius: 10px;
}

.prvecy-main-discription.prvecy-main-discription-new-scrollbar {
  overflow: unset !important;
  max-height: 100% !important;
}

.prvecy-main-discription h6 {
  font-size: 16px;
  font-weight: 700;
}

.prvecy-main-discription li {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}

.prvecy-main-discription ul {
  margin-bottom: 12px !important;
}

p.chat-reply-text-by-story {
  text-align: left;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 600;
}

.prvecy-main-discription {
  padding-left: 30px;
}

.name {
  font-weight: bold;
}

.emoji {
  margin-left: 5px;
}

.username {
  margin-left: 5px;
  color: #c3c3c3;
}

.status {
  margin-left: 5px;
  color: #c3c3c3;
}

.content {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.emojis {
  margin-right: 10px;
}

.thumbnail {
  width: 50px;
  height: 80px;
  background-color: #ccc;
  border-radius: 5px;
  overflow: hidden;
}

.attr-scroll {
  height: 400px;
  overflow: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}


.active-chat-user {
  background: #e7ecff;
}

.message-container button.ant-btn.ant-btn-default.ant-btn-icon-only.icon-button {
  width: 42px;
  height: 42px;
  background: #ffffff;
  font-size: 27px;
  border: none;
  margin-left: 5px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0px !important;
  border-radius: 50%;
  flex: 0 0 42px;
}

.logo-android.main-play-logo p img {
  width: 100%;
  max-width: 150px;
}


.social-signup-button {
  /* align-items: center; */
  justify-content: center;
  display: flex;
  gap: 16px;
}